import React from 'react';
import Nav from '../components/Nav';
import style from './About.module.css'
import { Routes, Route, Link } from 'react-router-dom';
import Intro from "./Intro";
import History from "./History";
import Vision from "./Vision";
import Partner from "./Partner";

import { useRef , useState, useEffect } from "react";
import Footer from '../components/Footer';
import Submenu from './Submenu';

function About (props){
  
  const subMenu = [
    {
      id:1,
      link:'/about/greeting',
      title:'인사말',
    },
    {
      id:2,
      link:'/about/history',
      title:'연혁',
    },
    {
      id:3,
      link:'/about/partner',
      title:'고객사',
    },
    {
      id:4,
      link:'/about/vision',
      title:'비전 / CI',
    },
  ]

  const [activeList, setActiveList] = useState(subMenu[0].id);


  return(
    <div className={style.aboutback}>
      <Nav />
      <div className='aboutWrap'>
        <div id={style.aboutBg}></div>
        <div className="titBox">
              <h2>회사소개</h2>
              <span>다옴솔루션은 끊임없는 변화와 혁신으로 <br /> IPT/IPCC 분야에서 최고로 인정받는 선도기업입니다.</span>
        </div>
        </div>
        {/* <div className='inner1200'> */}
            <div id="lnb" className="inner lnb1">
              <div className='inner1200'>   
                <Submenu subMenu={subMenu} activeList={activeList} setActiveList={setActiveList}/>
              </div>
            </div>
          {/* </div> */}
        

        <Routes>
          <Route path="/greeting" element={<Intro />} />
          <Route path="/history" element={<History />} />
          <Route path="/vision" element={<Vision />} />
          <Route path="/partner" element={<Partner />} />
        </Routes>
    <Footer />
    </div>
  
  )
}


export default About;