import React from 'react';
import './Youtube.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";    
import { useRef , useState, useEffect } from "react";

function YouTube (){
  const [activeTab, setActiveTab] = useState(0);
  const [videoUrl, setVideoUrl] = useState("mHPVM_xgNsI?si=IQZ0OOFXOLata8h6");


  const video = [
    {
      id:0,
      title:'서울시청 시연',
      src:"mHPVM_xgNsI?si=IQZ0OOFXOLata8h6"
    },
    {
      id:1,
      title:'완주군청 시연',
      src:"0o2reOo0ugs?si=XED16W76MrVIiPCP"
    },
    {
      id:2,
      title:'연제구청 시연',
      src:"WIceEx1F9w0?si=5weR--XsSdptqznl"
    },
    {
      id:3,
      title:'용산구청 시연',
      src:"EX3z5ujn4Tc?si=NWp1qxXIaRrF_eTN"
    },
  ]


  const videoList = video.map((video, index)=>{
    return(
          <li key={video.id}
          style={{background : activeTab == video.id ? "#ee3523" : ""}}
          >
            <a
          style={{color : activeTab == video.id ? "#fff" : ""}}
          data-active={activeTab == video.id ? "true" : "false"}
          onClick={(e)=>{
            setActiveTab(video.id);
            setVideoUrl(video.src)

          }}
        >{video.title}</a></li>
    )
  })

  return(
    <div className='youtubeback'>
        {/* <div className='news-text-wrap'>
          <h2>스마트레터링 Youtube</h2>
          <h5>다옴솔루션만의 스마트레터링 유튜브가 개설되었습니다.</h5>
          <FontAwesomeIcon icon={faCirclePlus} size='2xl' className='youtube-plus'/>
          <div className="video">
            <div className='video-list'>
            <iframe src="https://www.youtube.com/embed/EX3z5ujn4Tc?si=NWp1qxXIaRrF_eTN" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
            </iframe>
            <span>[다옴솔루션 스마트레터링]용산구청 시연</span>
            </div>

            <div className='video-list'>
            <iframe src="https://www.youtube.com/embed/EX3z5ujn4Tc?si=NWp1qxXIaRrF_eTN" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
            </iframe>
            <span>[다옴솔루션 스마트레터링]용산구청 시연</span>
            </div>

            <div className='video-list'>
            <iframe src="https://www.youtube.com/embed/EX3z5ujn4Tc?si=NWp1qxXIaRrF_eTN" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
            </iframe>
            <span>[다옴솔루션 스마트레터링]용산구청 시연</span>
            </div>
          </div>

      </div> */}
      <div className="inner1570">
      <div className='FlexWrap'>
        {/* <div className='left'>
          <img src='/assets/@sample2.jpg' /> 
        </div> */}

        <div className='titleBox'>
          <span className='why'>YouTube</span>
          <h2>스마트레터링 <br />
            유튜브 채널 오픈!
          </h2>
          <h5>
            다옴솔루션만의 스마트레터링 유튜브가 개설되었습니다.
          </h5>

          {/* <div className='intro-list'>
            <ul>
              <li className='btn6'><a>인삿말
              </a></li>
              <li className='btn6'><a>회사연혁</a></li>
              <li className='btn6'><a>CI</a></li>
              
            </ul>
          </div> */}
          <div className='buttonWrap'>
            <button className='overView' onClick={()=>{
              window.location.href='https://www.youtube.com/@smartlettering'
            }}><a>Youtube 보러가기<FontAwesomeIcon icon={faArrowRightLong}  /></a>
            </button>
          </div>
        </div>

        <div className='contentBox'>
          <div className='videoWrap'>
            <div className="video">
              <div>
                <iframe className='youTube' src={ "https://www.youtube.com/embed/" + videoUrl} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen>
                </iframe>
              </div>
            </div>
            <div className='videoList'>
              <ul>
                {videoList}
              </ul>
            </div>

        </div>
        </div>


        
      </div>
    </div>
    </div>
  )
}

export default YouTube;
