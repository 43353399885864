import React from 'react';
import './Header.css'
import Nav from './Nav'
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectFade, Navigation, Pagination } from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import "swiper/css/effect-fade";
import mainImg from '../img/n1.png';
import mainImg2 from '../img/n2.png';
import mainImg3 from '../img/n3.png';
import { useRef , useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faAngleRight} from "@fortawesome/free-solid-svg-icons";
import {faAngleLeft} from "@fortawesome/free-solid-svg-icons";

function Header (props){
  const [swiper, setSwiper] = useState(null);


  const nextButton = () => {
    swiper.slideNext();
  };

  const prevButton = () => {
    swiper.slidePrev();
  };

  const swiperStyle = {
    position: "relative",
    width: "100%",
    height: "100vh",
  };

  const swiperImgStyle = {
    width:'100%',
    height:'100%',
    objectFit: 'cover',
  }

  return(
    <header>
      <Swiper
        style={swiperStyle}
        modules={[Navigation, EffectFade, Pagination, Autoplay]}
        // pagination={{ clickable: true }}
        navigation={true}
        autoplay={{delay:5000 , disableOnInteraction:false}}
        loop={true}
        effect={"fade"}
        speed={800}
        onSlideChange={()=>{
   
        }}
        onSwiper={(s) => {
          setSwiper(s);
        }}

      >
        <SwiperSlide>
          {/* <img src={mainImg} style={swiperImgStyle} className='img1'/> */}
          <div className="bg img img1" id="bg1"></div>
          <div className="txtBox">
            <div className="inner1200 cnt">
              <p>
                모두가 <br />
                신뢰하는 기업<br /> <span>다옴솔루션</span>
              </p>
            </div>

            
          </div>

          <div className='inner1200'>
          <div className='mainButtonBox'>
            
              <button onClick={prevButton} className='mainPrevButton'> <FontAwesomeIcon icon={faAngleLeft }/> </button>
              <button onClick={nextButton} className='mainNextButton'><FontAwesomeIcon icon={faAngleRight} /></button>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="bg img img1" id="bg2"></div>
          <div className="txtBox">
            <div className="inner1200 cnt">
              <p>최고의 <br />
                경쟁력을 가진 <br /><span>다옴솔루션</span>
            
              </p>
            </div>

          </div>

          <div className="inner1200">
          <div className='mainButtonBox'>
              <button onClick={prevButton} className='mainPrevButton'> <FontAwesomeIcon icon={faAngleLeft }/> </button>
              <button onClick={nextButton} className='mainNextButton'><FontAwesomeIcon icon={faAngleRight} /></button>
            </div>
          </div>

        </SwiperSlide>
      </Swiper>
      <Nav outerDivRef={props.outerDivRef} scrollIndex={props.scrollIndex} setScrollIndex={props.setScrollIndex}
      innerWidth={props.innerWidth}  />
      {/* <div className="inner">
        <Link to="/test"><h2>IT 세상에서 모두가 신뢰 하는 기업</h2></Link>
      </div> */}
    </header>
  )
}


export default Header;