import React from 'react';
import Nav from '../components/Nav';
import style from './About.module.css'
import { Link } from 'react-router-dom';
import './Intro.css';
import ceoImg from '../img/ceoImg.jpg'


function Intro (props){



  return(
    <div id="sb">
      <div className='inner1200'>
        <h3 className="pageTit">인사말</h3>
        <div className='introWrap'>
          <div className='ceoImg'>
            <img src={ceoImg} />
          </div>

          <div className='ceoText'>
            <p>
            <strong className='bold'><span>다옴솔루션</span>은</strong> <br />
            끊임없는 변화와 혁신으로 IPT/IPCC 분야에서< br/>
            '최고로 인정받는 선도기업' 입니다.
            </p>

            <div className='line'></div>
            
            <p>
            <span>다옴솔루션은 IPT/IPCC 및 부가장비 컨설팅 및 구축, 개발, 유지보수 기업으로 
            고객사의 효율성을 극대화 시킬 수 있는 통신인프라 고도화 전문인력으로 구성되어 있습니다.<br /></span>

            <span>IPT/IPCC 분야에서 전문기술과 노하우를 보유한 우수한 직원들과 고객과의 신뢰관계를 기반으로
            PARTNER SHIP을 발휘하여 고객의 통신 인프라 경쟁력 강화와 효율성을 높이고,
            또한 고객의 NEEDS에 즉시 대응하여 고객 만족도를 높여 가고 있습니다. </span>
            <span>다옴솔루션은 기술력 향상을 위한 임직원의 능력 배양 지원과 
            참신한 인재 육성 등 모범적인 조직 문화를 만들기 위한 노력을 하고 있으며
            작지만 결속력이 강한 기업, 최고의 서비스 경쟁력을 갖추어 신뢰 받는 기업이 
            될 수 있도록 노력하겠습니다. </span>
            <span>앞으로도 끊임없이 도전하고 성장할 ㈜다옴솔루션에 많은 관심과 성원을 부탁 드립니다.</span>
            <strong className='bold'>대표이사 윤지경</strong>
            </p>
          </div>
        </div>
      </div>
      
    </div>
  
  )
}


export default Intro;