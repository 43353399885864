import React from 'react';
import './Nav.css'
import { useRef , useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';
import './MobileNav.css'

function MobileNav (props){
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState('') 

  const onClickHandler = (e) => {
    console.log(e.target.getAttribute('href'))
    setCurrentPath(e.target.getAttribute('href'))
  }

  useEffect(() => {
    if(currentPath === location.pathname) window.location.reload();
  }, [location]);


  return(
    <div className='mobileNav'>
        
            <ul>
              <li>
                <h3 className='menu'>MENU</h3>
              </li>
              <li>
              {/* <a style={{color:props.scrollIndex == 0 || props.scrollIndex == 2 ? "#fff" : "#000"}}> */}
                <Link to="/about/greeting" style={{color:props.scrollIndex == 0 || props.scrollIndex == 2 ? "#fff" : "#000"}} onClick={onClickHandler}>회사소개</Link>
              {/* </a> */}
              </li>
              <li><Link to="/solution/solution1" style={{color:props.scrollIndex == 0 || props.scrollIndex == 2 ? "#fff" : "#000"}} onClick={onClickHandler}>제품 및 솔루션</Link></li>
              <li><Link to="/recruit/recruit1" style={{color:props.scrollIndex == 0 || props.scrollIndex == 2 ? "#fff" : "#000"}} onClick={onClickHandler}>인재채용</Link></li>
              <li><Link to="/support/support1" style={{color:props.scrollIndex == 0 || props.scrollIndex == 2 ? "#fff" : "#000"}} onClick={onClickHandler}>고객지원</Link></li>
              <li><Link to="/news/report" style={{color:props.scrollIndex == 0 || props.scrollIndex == 2 ? "#fff" : "#000"}} onClick={onClickHandler}>뉴스</Link></li>
            </ul>
          </div>
  )
}


export default MobileNav;