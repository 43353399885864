import React from 'react';
import Nav from '../components/Nav';
import style from './About.module.css'
import Submenu from './Submenu';
import Footer from '../components/Footer';
import { useRef , useState, useEffect } from "react";
import './Recruit.css';
import { Routes, Route, Link } from 'react-router-dom';
import Support1 from './Support1';
import Support2 from './Support2';
import newsStyle from './News.module.css';
import './News.css';
import Report from './Report';
import Notice from './Notice';
import ReportTemplate from './ReportTemplate';

function News (props){

  // const news = [
  //   {
  //     id:0,
  //     title:`[전자신문] 다옴솔루션, '스마트레터링' 시장서 인기몰이`,
  //     img:`/assets/news-1.png`,
  //     date:`2023-10-17`,
  //     source:`https://m.etnews.com/20231017000294`,
  //     desc:`다옴솔루션(대표 윤지경)이 내놓은 홍보플랫폼 '스마트레터링 솔루션'을 선보여 업계 이목을 끌고 있다. 17일 업계에 따르면 다옴솔루션 스마트레터링 서비스는 경기도 양주시청, 서울 영등포구청, 부산 남구청, 서울 용산구청 등에 구축, 공급되며 시장에서 큰 반향을 일으키고 있다. 솔루션은 지자체와 	공공기관에서 전화 수·발신 시 보이스피싱 예방 등을 지원한다. 강연과 각종교육, 설문조사, 축제, 행사, 대관안내, 	지원금 신청, 각종 지원사업 등을 매일 지자체와 공공기관과 통화하는 민원인 스마트폰에 통화 전후로 팝업해 지원한다.
  //     솔루션은 안드로이드 OS 스마트폰 (콜게이트 런처 설치 스마트폰) 기반 서비스 임에도 카카오 알림톡과 SMS와 연동해 레터링 미지원 스마트폰에도 부서명과 행정전화번호, 각종 축제 관련 정보를 표시해 지원한다.

  //     윤지경 다옴솔루션 대표는 “서비스를 이용 중인 지자체에서는 스마트레터링 에필로그에 카드뉴스, 유튜브 영상, SNS 모음링크, 레터링 수신거부 기능 등을 자체 개발 적용해 서비스를 제공하며 민원인의 호평을 받고 있다”며 “양주시청에서는 월 21만건 레터링 서비스를 지원하는 등 우수한 성과를 거뒀댜”고 말했다. 이어 “스마트레터링을 통해 지자체와 공공기관의 전통적인 소통방식을 혁신하고 민원인들에게 새로운 경험을 제공할 것”이라며 “지난해 조달청 디지털서비스몰에 상용SW로 도입한 상태이며, 향후 공공과 민간 전영역을 아우르는 서비스로 고도화할 계획”이라고 덧붙였다.`
  //   },
  //   {
  //     id:1,
  //     title:`[경기/양주시] 도내 지방정부 최초 '스마트 행정전화 알림' 서비스 시행'시민과의 소통 플랫폼' 기대`,
  //     img:`/assets/news-2.png`,
  //     date:`2023-04-10`,
  //     source:`https://www.localnewsroom.co.kr/news/articleView.html?idxno=1737`,
  //     desc:`[한국지방정부신문=김지수 기자] 경기도 양주시(시장 강수현)가 경기도 지방정부 최초로 스마트 행정전화 알림 서비스를 도입하고, 행정전화에 대한 신뢰도와 민원행정 서비스 향상에 힘쓰고 있다. 스마트 행정전화 알림서비스는 시민들이 시청으로 전화를 발신, 수신하는 경우 스마트폰 화면에 시청 로고와 행정전화 부서 등이 자동으로 표시돼 수·발신 부서 정보를 알려주는 서비스이다.
  //     이번 서비스는 시민들이 공무 목적으로 발신된 행정전화를 광고, 스팸 전화로 오인하고 전화를 거절하는 사례가 빈번하게 발생함에 따라 원활한 통화 연결과 행정 처리가 지연되는 상황을 해소하기 위해 마련했다.

  //     통화 수·발신 시 민원인의 스마트폰 화면에 행정전화 수·발신번호와 부서정보가 제공되며, 통화 종료 후에는 문화행사, 관광, 생활 등 주요 시정 소식을 시각화한 이미지로 제공해 행정 편의는 물론 시정 홍보까지 일석이조의 효과가 기대된다.

  //     스마트 행정전화 알림서비스는 시청과 보건소, 사업소, 읍면동 행정복지센터 등 전 부서 행정전화에 적용된다. 스마트폰 이미지 표시 제공은 안드로이드 기반 스마트폰 기종에만 적용되며 아이폰 사용자는 애플 정책상 카카오톡 알림톡 서비스만 제공된다.

  //     강수현 시장은 “시민들을 위한 다양한 시책과 축제, 행사 등이 이번 서비스를 통해 널리 홍보되고 시민들에게 다양한 혜택이 돌아갈 것으로 기대된다”며, “앞으로도 최신 정보통신 기술을 도입해 시민들의 편의 증진을 위한 다양한 시정 아이디어를 적극적으로 발굴, 반영하겠다”고 전했다.

  //     출처 : 한국지방정부신문(http://www.localnewsroom.co.kr) 민원인의 호평을 받고 있다”며 “양주시청에서는 월 21만건 레터링 서비스를 지원하는 등 우수한 성과를 거뒀댜”고 말했다. 이어 “스마트레터링을 통해 지자체와 공공기관의 전통적인 소통방식을 혁신하고 민원인들에게 새로운 경험을 제공할 것”이라며 “지난해 조달청 디지털서비스몰에 상용SW로 도입한 상태이며, 향후 공공과 민간 전영역을 아우르는 서비스로 고도화할 계획”이라고 덧붙였다.`
  //   }
  // ]

  // const truncate = (str, n) => {
  //   return str?.length > n ? str.substring(0, n - 1) + "..." : str;
  // };

  // const [viewReport, setViewReport] = useState(false)
  // const [target, setTarget] = useState('')
  // const [newsId , setNewsId] = useState(null)

  // const handleEvent = (e) =>{
    
  //   setViewReport(true)
  //   setTarget(e.currentTarget.id)
  // }


  
  // if(viewReport == true){

  // }
  // const newsList = news.map((news, index)=>{
  //   return (
  //       <li key = {news.id} className="news-info-list"
  //       onClick={handleEvent} id={index}>
  //         <Link to='/news/report/reporttemplate'>
  //           <div className='newsWrap'>
  //             <div className="thmb">
  //               <img src={news.img} alt="뉴스1" />
  //             </div>
  //             <div className='descWrap'>
  //                 <div className="sub">
  //                   <strong className='newsTit'>{truncate(news.title , 35)}</strong>
  //                   <span className={newsStyle.date}>{news.date}</span>
  //                 </div>
  //                 <div className={newsStyle.desc}>{truncate(news.desc , 200)}
  //                 </div>
  //               </div>
  //             </div>
  //           </Link>
  //       </li>
  //   )
  // })



  const subMenu = [
    {
      id:1,
      link:'/news/report',
      title:'보도자료',
    },
    {
      id:2,
      link:'/news/notice',
      title:'공지사항',
    },
    
  ]

  const [activeList, setActiveList] = useState();

 

  return(
    <div className={style.aboutback}>
      <Nav />
      <div className='aboutWrap'>
        <div id={newsStyle.aboutBg}></div>
          <div className="titBox">
            <h2>뉴스</h2>
            <span>다옴솔루션은 끊임없는 변화와 혁신으로 <br /> IPT/IPCC 분야에서 최고로 인정받는 선도기업입니다.</span>
        </div>
      </div>


      <div id="lnb" className="inner lnb1">
        <div className='inner1200'>   
          <Submenu subMenu={subMenu} activeList={activeList} setActiveList={setActiveList}/>
        </div>
     </div>


     <Routes>
        <Route path="/report/*" element={<Report />} />
        <Route path="/notice/*" element={<Notice />} />
     </Routes>

  <Footer />
  </div>
  
  )
}


export default News;