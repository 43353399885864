import React from 'react';
import Nav from '../components/Nav';
import style from './About.module.css'
import Submenu from './Submenu';
import Footer from '../components/Footer';
import { useRef , useState, useEffect } from "react";
import { Routes, Route, Link } from 'react-router-dom';
import './Recruit.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCircleInfo} from "@fortawesome/free-solid-svg-icons";
import KakaoMap from './KakaoMap';

function Support2 (props){




  return(
    <div id="sb">
      <div className='recruit'>
        <div className='inner1200'>
          {/* <div className='titleWrap2'>
              <h1 className='solutionTit2'>채용공고</h1>
          </div> */}

        {/* <h3 class="pageTit">오시는 길</h3>  */}
          <KakaoMap />

          <div className="map_info">
            <div className="info_div div1">
                {/* <div class="icon"></div> */}
                <ul>
                    <li><strong>주소</strong></li>
                    <li>서울특별시 금천구 디지털로 10길 78 641,642,643호</li>
                </ul>
            </div>
            <div className="info_div div2">
              
                {/* <div class="icon"></div> */}
                <ul>
                    <li><strong>전화번호</strong></li>
                    <li>02-6230-6700</li>
                </ul>

                <ul>
                    <li><strong>팩스</strong></li>
                    <li>02-6230-6701</li>
                </ul>

                <ul>
                    <li><strong>이메일</strong></li>
                    <li>hj.jang@daomsolution.com</li>
                </ul>
            </div>
        </div>
  		</div>
  	</div>
  </div>



  
  )
}


export default Support2;