import React from 'react';
import Nav from '../components/Nav';
import style from './About.module.css'
import { Link } from 'react-router-dom';
import './Vision.css';
import vision from './Vision.module.css'
import visionImg1 from '../img/vision01.png';
import visionImg2 from '../img/vision02.png';
import visionImg3 from '../img/vision03.png';
import logo from '../img/ci.png';
import whiteLogo from '../img/w-ci.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLightbulb } from "@fortawesome/free-regular-svg-icons";

function Vision (){



  return(
    <div id="sb">
      <div className='inner1200'>
        <h3 className="pageTit">비전</h3>
        <div className='subTit'>
          <span className="adTit_pc">" 아름다운 생각들이 모이면 세상은 새로워집니다 "</span>
          <span className={vision.adTit_mobile}>" 아름다운 생각들이 모이면 <br />세상은 새로워집니다 "</span>
          <span>㈜다옴솔루션은, 고객 만족을 중시하는 마인드를 보유한 모든 임직원을 주축으로
            급변하는 통신 시장에 능동적으로 대처하며 최고의 기술력으로 누구보다 빠른 대처와
            남들보다 한발 앞서는 선도 기업으로 발전하기 위해 항상 노력하고 있습니다</span>
        </div>
        <div className='visionWrap value'>
          <div className='logo'>
            <img src={logo} />
          </div>
          <div className="dotLine"></div>
          <ul className="flexBox just-center">
            <li>
              {/* <div className="icon"><FontAwesomeIcon icon={faLightbulb} /></div> */}
              <dl className='valueOne'>
                <dt>Innovation</dt>
                <dd>혁신</dd>
              </dl>
              <p className='visionText'>
                  차세대 기술 개발에 <br /><strong>끊임없이 도전하고 변화</strong>하는 <br />기업이 되겠습니다.</p>
            </li>
            <li>
              {/* <div className="icon"><img src={visionImg2}  alt="" /></div> */}
              <dl className='valueOne'>
                <dt>Trust</dt>
                <dd>신뢰</dd>
            
              </dl>
              <p className='visionText'><strong>고객과의 신뢰</strong>를 바탕으로 <br/>
                성공적인 비즈니스를<br />
                이끌겠습니다.</p>
            </li>
            <li >
              {/* <div className="icon"><img src={visionImg3}  alt="" /></div> */}
              <dl className='valueOne'>
                <dt>Collaboration</dt>
                <dd>협업
                </dd>
            
              </dl>
              <p className='visionText'>항상 고객의 입장에서 생각하여<br />
                  <strong>최적의 서비스</strong>로<br />
                  보답하겠습니다.</p>
            </li>
          </ul>  
        </div>   

        <h3 className="pageTit CI">CI</h3>  
          <div className='subTit' id='CITit'>
          <span className='CIText'>다옴솔루션의 CI 는 ‘다옴’ 은 하는 일마다 복이 온다는 뜻으로 <br />영문이니셜인 ‘D’ 와 ‘S’ 에 RED 계열 색상을 사용하여 젊고, 열정적이며 에너지 넘치는 이미지를 부합시켰습니다</span>
        </div>
        <div className='CIWrap'>
          <div className='logoImg'>
                <div className='blackLogo'>
                  <img src={logo} />
                </div>
                {/* <div className='whiteLogo'>
                  <img src={whiteLogo} />
                </div> */}
          </div>
        </div>
      </div>
      
    </div>
    )
}

export default Vision;