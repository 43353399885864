import { useRef, useState } from "react";
import React, { useEffect } from "react";
import './App.css';
import Main from "./components/Main";
import Test from './components/Test';
import { Routes, Route } from 'react-router-dom';
import About from "./pages/About";
import Intro from "./pages/Intro";
import Solution from "./pages/Solution";
import Recruit from "./pages/Recruit";
import Support from "./pages/Support";
import News from "./pages/News"


function App() {
  const [number, setNumber] = useState(0)

  
  return (
    <div className="App">
      <div className='outer'>
        <Routes>
          <Route path="/" element={<Main />} exact />
          <Route path="/main" element={<Main />} exact />
          <Route path="/about/*" element={<About />} />
          <Route path="/solution/*" element={<Solution />} />
          <Route path="/recruit/*" element={<Recruit />} />
          <Route path="/support/*" element={<Support />} />
          <Route path="/news/*" element={<News />} />
        </Routes>
      </div>
      {/* <Routes>
        <Route path="/test" element={<Test />} />
      </Routes> */}
    </div>
  )
}



export default App;
