import React from 'react';
import Nav from '../components/Nav';
import style from './About.module.css'
import Submenu from './Submenu';
import Footer from '../components/Footer';
import { useRef , useState, useEffect } from "react";
import { Routes, Route, Link } from 'react-router-dom';
import './Recruit.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCircleInfo} from "@fortawesome/free-solid-svg-icons";

function Recruit2 (props){




  return(
    <div id="sb">
      <div className='recruit'>
        <div className='inner1200'>

           <div className='titleWrap2'>
              <h4 className="adTit adTit_pc">다옴솔루션은 여러분의 열정적 도전을 기다립니다.  </h4>
              <h4 className="adTit adTit_mobile">다옴솔루션은 여러분의 <br />열정적 도전을 기다립니다.  </h4>
          </div> 

          {/* <div className='noRecruit'>
						<img src="/assets/info.png" alt=""/>
						<p>현재 진행중인 채용공고가 없습니다.</p>
					</div> */}
          
        <div className="recruit_info">
          <ul>
            <li>
              <div className="recruit_box recruit_box1">
                <div>
                  <div>
                    <strong>솔루션 영업 채용</strong>
                  </div>
                  {/* <div class="desc">
                    
                  </div> */}
                </div>
              </div>
            </li>
            
            <li>
              <div className="recruit_box recruit_box2">
                <div>
                  <div>
                    <strong>개발자 채용</strong>
                  </div>
                  

                </div>
              </div>
            </li>

            <li>
              <div className="recruit_box recruit_box3">
                <div>
                  <div>
                    <strong>기술지원 채용</strong>
                  </div>
                  

                </div>
              </div>
            </li>
          </ul>
        
          <div className='recruit_subinfo'>
            <p>
              - 다옴솔루션의 모집시기는 상시채용입니다. 메일에 이력서를 첨부해 주세요.
            </p>
          </div>
        </div>

      
          <div className="recruitBtn">
            <a href="mailto:'sw.jo@daomsolution.com'">지원하기</a>
          </div>

       
  		</div>
  	</div>
  </div>



  
  )
}


export default Recruit2;