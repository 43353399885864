import React, { useEffect } from 'react';
import Nav from '../components/Nav';
import style from './About.module.css'
import { NavLink } from 'react-router-dom';
import PaginationStyle from './Pagination.module.css';
import { Routes, Route, Link, useLocation } from 'react-router-dom';

function Pagination (props){


 const pageList = [];
 const totalPages = Math.ceil(props.postsNum / props.postsPerPage);
//  console.log(totalPages)
//  console.log(props.postsNum)
//  console.log(props.postsPerPage)

 for (let i = 1; i <= totalPages; i++) {
   pageList.push(i);
 };

 const goToNextPage = () => {
  props.setCurrentPage(props.currentPage + 1);
};

const goToPrevPage = () => {
  props.setCurrentPage(props.currentPage - 1);
};

const location2 = useLocation();
//  console.log(totalPages)


  return(
    <div className={PaginationStyle.buttonWrap} style={{display : location2.pathname === '/news/report/reportTemplate' ? "none" : "block"}}>
      <button onClick={goToPrevPage} disabled={props.currentPage === 1} className={PaginationStyle.arrow1}>
        &lt;
      </button>

      <div className={PaginationStyle.buttonWrap2}>
      {pageList.map((page) => (
        <button
          key={page}
          onClick={() => props.setCurrentPage(page)}
          className={props.currentPage === page ? PaginationStyle.active : ""}
        >
          {page}
        </button>
      ))
      }
      </div>

      <button onClick={goToNextPage} disabled={props.currentPage === pageList.length} className={PaginationStyle.arrow2}>
        &gt;
      </button>
    </div>
  
  )
}


export default Pagination;