import React from 'react';
import Nav from '../components/Nav';
import style from './About.module.css'
import Submenu from './Submenu';
import Footer from '../components/Footer';
import { useRef , useState, useEffect } from "react";
import { Routes, Route, Link } from 'react-router-dom';
import './Recruit.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faPhone, faDesktop} from "@fortawesome/free-solid-svg-icons";
import './Support.css';


function Support2 (props){

  const [isCheckingBox, setIsCheckingBox] = useState(false)

  const checkingCheckedBox = () => {
    setIsCheckingBox(!isCheckingBox)
  }

  const alertBox = () => {
    const submit = document.getElementById('submit')
    if(isCheckingBox === true){
      submit.setAttribute('href', "mailto:'cj.yun@daomsolution.com'")
    }else if(isCheckingBox === false){
      submit.removeAttribute('href');
      alert('개인정보 취급방침에 동의해주세요.')
    }
  }


  return(
    <div id="sb">
      <div className='recruit'>
        <div className='inner1200'>
          {/* <div className='titleWrap2'>
              <h1 className='solutionTit2'>채용공고</h1>
          </div> */}

        {/* <h3 class="pageTit">문의하기</h3>  */}
        <div className="contact_info">
          <ul>
              <li>
                  <div className="info_box info_tel">
                      <div>
                      {/* <FontAwesomeIcon icon={faPhone} /> */}
                        <div className="tit2"><strong>전화 문의 <span>02-6230-6700</span> </strong></div>
                        <div className="desc">위 번호로 전화주시면 각 솔루션의 <br /> 담당자에게 보다 자세한 상담을 받을 수 있습니다.</div>
                        {/* <div class="tel_pkg">
                            <strong class="tel">02-6230-6700 </strong>
                          
                        </div> */}
                      </div>
                  </div>
              </li>
              <li>
                  <div className="info_box info_online">
                      <div>
                      {/* <FontAwesomeIcon icon={faDesktop} /> */}
                        <div className="tit2"><strong>온라인 문의</strong></div>
                        <div className="desc">아래 간단한 정보를 남겨주시면 각 솔루션의 <br /> 담당자에게 보다 자세한 상담을 받을 수 있습니다.</div>
                      </div>
                  </div>
              </li>
          </ul>
        </div>
       
       <div className='formWrap'>
          <form name="submit">
          <div className="personal"><span>다옴솔루션</span>은 (이하 '회사'는) 고객의 개인정보를 중요시하며, "정보통신망 이용촉진 및 정보보호"에 관한 법률을 준수하고 있습니다. 회사는 개인정보취급방침을 통하여 고객께서 제공하시는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다. <br />
            회사는 개인정보취급방침을 개정하는 경우 웹사이트 공지사항(또는 개별공지)을 통하여 공지할 것입니다. <br />
            본 방침은 : 2015 년 01 월 01 일부터 시행됩니다.
            
            <span className='formTit'>제1조(개인정보의 이용 목적)</span>
            회사는 다음의 목적을 위하여 개인정보를 이용합니다. 수집된 개인정보는 다음의 목적 이외의 용도로 이용되지 않으며, 이용 목적이 변경되는 경우에는 개인정보 보호법 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.  <br />
            • 고객지원 <br />
            고객의 신원확인, 문의사항 확인, 사실조사를 위한 연락, 통지, 처리결과 통보 등의 목적으로 개인정보를 이용합니다. <br />
            • 온라인 입사지원 <br />
            입사지원자의 신원확인, 지원내용 확인, 지원 처리결과 통보 등의 목적으로 개인정보를 이용합니다.
            
            <span className='formTit'>제2조(개인정보 수집 페이지 및 항목)</span>
            • 수집페이지 <br />
            교육정보, A/S신청, 견적요청, 1:1문의, 방문신청, 온라인 입사지원 <br />
            • 수집항목: <br />
            - 교육정보, A/S신청, 견적요청, 1:1문의, 방문신청: 이름, 연락처, 이메일, 주소, 회사명, 보유장비, 제품명, 고장 증상 <br />
            - 온라인 입사지원: 이름, 생년월일, 성별, 본적, 자택 전화번호, 자택 주소, 휴대 전화번호, 이메일, 신장, 체중, 혈액형, 시력, 가족사항, 결혼여부, 취미, 특기, 병역 정보, 학력, 자격증, 증명사진, 외국어능력, 경력 사항, 자기 소개서
            
            <span className='formTit'>제3조(개인정보의 보유 및 이용기간)</span>
            회사는 수집된 개인정보를 다음의 기준에 따라 보유합니다. <br />
            • 고객지원 메뉴를 통해 수집된 개인정보 : 1년 <br />
            • 온라인 입사지원을 통해 수집된 개인정보 : 2년 <br />
            • 인터넷 로그기록: 3개월  <br />
            • 예외 사유: 관계 법령 위반에 따른 수사.조사 등이 진행 중인 경우에는 해당 수사.조사 종료 시까지
            
            <span className='formTit'>제4조(개인정보 수집에 대한 거부 권리)</span>
            (1) 이용자는 회사가 수집하는 개인정보에 대해 동의 절차를 거치고 있으며, 이용자의 동의가 이루어진 경우에만 수집하고 있습니다. <br />
            (2) 이용자는 개인정보 수집 동의를 하지 않음으로써 개인정보 수집을 거부할 수 있습니다. <br />
            (3) 제2항의 개인정보 수집 동의를 거부하시는 경우 이용자의 민원 및 입사지원자의 신원확인이 불가능하기 때문에 고객지원 메뉴 및 입사지원서 페이지를 통한 신청 작성이 불가합니다. 
            </div>

            <label htmlFor="privacy">
              <input type="checkbox" id="privacy" onClick={checkingCheckedBox} />
              개인정보 취급방침에 동의합니다.
            </label>
              {/* <input type="submit" value="SEND MESSAGE" id="submit" /> */}

              <div className='submitWrap'>
                <a id="submit" className="send"
                onClick={alertBox}
                >SEND MESSAGE</a>
              </div>
          
        </form>
       </div>
  		</div>
  	</div>
  </div>



  
  )
}


export default Support2;