import React from 'react';
import './Header.css';
import './Main.css'
import Header from './Header';
import Company from './Company';
import Solution from './Solution';
import Youtube from './Youtube';
import Footer from './Footer';
import { Link } from 'react-router-dom';
import { useRef , useState, useEffect } from "react";
import $ from 'jquery';
import { debounce } from 'lodash';




function Main (){
  const outerDivRef = useRef();
  const [scrollIndex , setScrollIndex] = useState(0);

  let lastWidth = window.innerWidth;
 
    const [innerWidth, setInnerWidth] = useState(window.innerWidth);
    
    const resizeListener = () => {
      setInnerWidth(window.innerWidth);
      if(window.innerWidth != lastWidth){
        window.location.reload();
      }
      
    };
  
    useEffect(() => {
      window.addEventListener("resize", resizeListener);
  
      return () => {
        window.removeEventListener("resize", resizeListener);
      };
    }, []); // 빈 배열을 전달하여 컴포넌트가 마운트될 때 한 번만 실행되도록 설정
  
    




  useEffect(() => {

      if(innerWidth > 1345){
      
      const wheelHandler = (e) => {
        e.preventDefault();

        const { deltaY } = e;
        const { scrollTop , scrollHeight } = outerDivRef.current;
        const pageHeight = window.innerHeight;

        // 스크롤 내릴때
        if(deltaY > 0) {
         
         
            if(scrollTop >=0 && scrollTop < pageHeight) {
              outerDivRef.current.scrollTo({
                top: pageHeight 
                ,left:0
                ,behavior:"smooth"
              });
              setScrollIndex(1);
            }
            else if(scrollTop >= pageHeight && scrollTop < pageHeight * 2) {
              outerDivRef.current.scrollTo({
                top: pageHeight*2
                ,left:0
                ,behavior:"smooth"
              });
              setScrollIndex(2);
            }
            else if(scrollTop >= pageHeight && scrollTop < pageHeight * 3) {
              outerDivRef.current.scrollTo({
                top: pageHeight*3
                ,left:0
                ,behavior:"smooth"
              });
              setScrollIndex(3);
            }
            else {
              outerDivRef.current.scrollTo({
                top: pageHeight*4
                ,left:0
                ,behavior:"smooth"
              });
              setScrollIndex(4);
            }
           
        }
        // 스크롤 올릴때
        else {
          if(parseInt((scrollTop +  pageHeight)) === scrollHeight) {
            outerDivRef.current.scrollTo({
              top: pageHeight * 3,
              left: 0,
              behavior: "smooth",
            });
            setScrollIndex(3);
          } else {
            if(scrollTop >=0 && scrollTop < pageHeight) {
              outerDivRef.current.scrollTo({
                top:0
                ,left:0
                ,behavior:"smooth"
              });
              setScrollIndex(0);
            }
            else if(scrollTop >= pageHeight && scrollTop < pageHeight * 2) {
              outerDivRef.current.scrollTo({
                top:0
                ,left:0
                ,behavior:"smooth"
              });
              setScrollIndex(0);
            }
            else if(scrollTop >= pageHeight * 2 && scrollTop < pageHeight * 3) {
              outerDivRef.current.scrollTo({
                top: pageHeight 
                ,left:0
                ,behavior:"smooth"
              });
              setScrollIndex(1);
            }
            else if(scrollTop >= pageHeight * 3 && scrollTop < pageHeight * 4) {
              outerDivRef.current.scrollTo({
                top: pageHeight * 2,
                left: 0,
                behavior: "smooth",
              });
              setScrollIndex(2);
            }
           
           
          }


        }
           
            
        


      }

      
      const outerDivRefCurrent = outerDivRef.current;
      outerDivRefCurrent.addEventListener("wheel" , wheelHandler);
      return () => {
        outerDivRefCurrent.removeEventListener("wheel", wheelHandler);
      };
  }}, [innerWidth]);
 
    
  
  


  return(
      <div>
        <div ref={outerDivRef} className = "wrap outer" style={{height: innerWidth > 1345 ? "100vh" : "auto", overflowY:"auto"}}>
          <div className='main-inner scrollContainer'><Header outerDivRef={outerDivRef} scrollIndex={scrollIndex} setScrollIndex={setScrollIndex} innerWidth={innerWidth}  /></div>
          <div className='main-inner scrollContainer'><Company /></div>
          <div className='main-inner scrollContainer'><Solution /></div>
          <div className='main-inner scrollContainer'><Youtube /></div>
          <div><Footer/></div>
        </div>    
       </div>
  )
}


export default Main;