import React from 'react';
import Nav from '../components/Nav';
import style from './About.module.css'
import Submenu from './Submenu';
import Footer from '../components/Footer';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMobile } from "@fortawesome/free-solid-svg-icons";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import {faAngleRight} from "@fortawesome/free-solid-svg-icons";
import {faAngleLeft} from "@fortawesome/free-solid-svg-icons";
import { useRef , useState, useEffect } from "react";
import { Routes, Route, Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectFade, Navigation, Pagination } from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import "swiper/css/effect-fade";
import Modal from './Modal';

function Solutionsmart (props){
  const [swiper, setSwiper] = useState(null);

  const nextButton = () => {
    swiper.slideNext();
  };

  const prevButton = () => {
    swiper.slidePrev();
  };

  let [modal, setModal] = useState(false);
  const [modalImg , setModalImg] = useState();

  const items = [
    {
      id:0,
      img:'/assets/logo/jejudo.png',
      title:'제주도청',
      modalImg :`<img src="/assets/modal/pr-jejudo.jpg" />
      <img src="/assets/modal/pr2-jejudo.jpg" />
      <img src="/assets/modal/ep-jejudo.jpg" />
      <img src="/assets/modal/ep2-jejudo.jpg" />
      `,
     
    },
    {
      id:1,
      img:'/assets/logo/pyeongtaek.png',
      title:'평택시청',
      modalImg :`<img src="/assets/modal/pr-pyeongtaek.jpg" />
      <img src="/assets/modal/ep-pyeongtaek.jpg" />
      <img src="/assets/modal/ep2-pyeongtaek.jpg" />
      <img src="/assets/modal/ep3-pyeongtaek.jpg" />
      `,
     
    },
    {
      id:2,
      img:'/assets/logo/yongsan.png',
      title:'용산구청',
      modalImg :`<img src="/assets/modal/pr-yongsan.jpg" />
      <img src="/assets/modal/pr2-yongsan.jpg" />
      <img src="/assets/modal/ep-yongsan.jpg" />
      <img src="/assets/modal/ep2-yongsan.jpg" />
      `,
      
    },
    {
      id:3,
      img:'/assets/logo/bsnamgu.png',
      title:'부산남구청',
      modalImg :`<img src="/assets/modal/pr-bsnamgu.jpg" />
      <img src="/assets/modal/pr2-bsnamgu.jpg" />
      <img src="/assets/modal/ep-bsnamgu.jpg" />
      <img src="/assets/modal/ep2-bsnamgu.jpg" />
      `,
    },
    {
      id:4,
      img:'/assets/logo/gimpo.png',
      title:'김포시청',
      modalImg :`<img src="/assets/modal/pr-gimpo.jpg" />
      <img src="/assets/modal/pr2-gimpo.jpg" />
      <img src="/assets/modal/ep-gimpo.jpg" />
      <img src="/assets/modal/ep2-gimpo.jpg" />
      `,
     
    },
    {
      id:5,
      img:'/assets/logo/yangju.png',
      title:'양주시청',
      modalImg :`<img src="/assets/modal/pr-yangju.jpg" />
      <img src="/assets/modal/pr2-yangju.jpg" />
      <img src="/assets/modal/ep-yangju.jpg" />
      <img src="/assets/modal/ep2-yangju.jpg" />
      `,
    },
    {
      id:6,
      img:'/assets/logo/ydp.png',
      title:'영등포구청',
      modalImg :`<img src="/assets/modal/pr-ydp.jpg" />
      <img src="/assets/modal/ep-ydp.jpg" />
      <img src="/assets/modal/ep2-ydp.jpg" />
      <img src="/assets/modal/ep3-ydp.jpg" />
      `,
    },
  ]

  const itemList = items.map((item, index)=>{

    return (
      <SwiperSlide key={item.id}>
      <div className='items' onClick={()=>
        {
          setModal(true)
          setModalImg(item.modalImg)
        }}
        >
          <div className='item-list-text'>
            <img src={item.img}></img>
            {/* <span>{item.title}</span> */}
          </div>
          <div className='hoverTextWrap'>
          <div className='hoverText'>
              <h3 className='solutionTitle'>{item.title}</h3>
              <p>
              {item.text}
              </p> 
              <button className='overView solutionOverView' ><a>이미지 보기<FontAwesomeIcon icon={faArrowRightLong}  /></a></button>
            </div>
            </div>
        </div>
      </SwiperSlide>
    )
  })



  return(
    <div id="sb2">

      <div className='product'>
        <div className='inner1200'>
          <div className='titleWrap'>
              <h1 className='solutionTit'>Smart Lettering</h1>
              <p className='solutionText'>스마트 레터링 v1.0 시스템은 내부 직원이 고객 또는 민원인에게 전화를 걸고 받을 경우 수신자 스마트 폰에 지자체 또는 공공기관 이미지와 해당 부서 정보를 팝업 표시 하여 주는 시스템으로 보이스 피싱 예방 및 각종 홍보 안내를 할 수 있는 서비스 입니다. <br /><br />
              또한 통화 종료 후 다시걸기 버튼 외에 모바일 WEB 페이지를 연결할 수 있는 링크를 제공하여 설문조사, 사전예약접수, 신청 등의 업무 안내를 제공 할 수 있으며 인바운드 전화에 대하여 동일하게 수신 측 홍보 이미지와 해당 부서 정보를 팝업 표시하여 정상적으로 전화를 걸었는지 확인 할 수 있도록 서비스를 제공 할 수 있는 통신 소프트웨어 입니다. (콜게이트 공유앱 런처 설치폰 대상 서비스 제공)
              </p>
            </div>
        </div>
      </div>

        <div className='pr_ep'>
          <div className='inner1200'>
          <h4 className='adTit adTit_pc'><span>다양한 컨텐츠</span> 노출이 가능한 <span>프롤로그</span>와 <span>에필로그</span></h4>
          <h4 className='adTit adTit_mobile'><span>다양한 컨텐츠</span> 노출이 가능한 <br /><span>프롤로그</span>와 <span>에필로그</span></h4>

          <p className='adSubTit'>통화 연결 전과 통화 연결 후 <br />
            다양한 컨텐츠 노출이 가능합니다.
          </p>
          <div className='SLImg'>
            <div className='pr'>
              <p><span>프롤로그</span>
              통화 연결 전까지 이미지 레터링 제공<br />
              공공기관/회사이미지<br/>
              부서정보 CID<br/>
              보이스피싱 예방 효과
              </p>
              <img src="/assets/warrow1.png" className='arrow arrow1'/>
              <img src="/assets/solution/pr.png" className='prImg'/>
            </div>
            <div className='ep'>
              <img src="/assets/warrow2.png" className='arrow arrow2'/>
              <img src="/assets/solution/ep.png" className='epImg'/>
              <p><span>에필로그</span>
                통화 종료 시점 Contents 제공<br/>
                각종정책 홍보/축제 안내<br/>
                재난지원금 신청<br/>
                사전예약 링크<br/>
                시민투표.설문조사 링크<br/>
                열린시장실/군수실 링크<br/>
                유튜브 홍보영상 
              </p>
            </div>
          </div>
        </div>
      </div>

        <div className='SLFuncWrap'>
          <div className='inner1200'>
            <h4 className='adTit'>스마트레터링의 <span>기대효과</span></h4>
            <ul>
                <li>
                  <div className="box">신뢰도 향상 및 보이스피싱 예방가능</div>
                </li>
                <li>
                  <div className="box">각종 민원전화 통화율 증가</div>
                  
                </li>
                <li>
                  <div className="box">주요 시정 / 정책 홍보 효과</div>
                </li>
                <li>
                  <div className="box">각종 홍보 컨텐츠 접근성 향상</div>
                </li>
              </ul>

              <div className='table outIn'>
                <table>
                  <thead>
                    <tr>
                      <th>아웃바운드</th>
                      <th>인바운드</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <p>
                          <span>아웃바운드</span>모르는 전화에 대한 거부감 감소<br />
                  스팸전화 / 보이스피싱 / 예방 효과<br/>
                  보험, 대출, 상품홍보 전화 대비효과<br/>
                  공공기관 / 지자체 전화에 대한인지도 향상과 전화 신뢰도 향상<br/>
                  민원인 발신 업무 담당자의 업무 효율성 증가
                        </p>
                      </td>
                      <td>
                        <p>
                          <span>인바운드</span>
                          지자체 / 공공기관 수신 민원 전화에 대하여 음성 컬러링 외 <br />보이는 컬러링 효과 제공<br/>
                          통화 연결 정확도 향상을 통하여 업무 담당자 연결 및 효율성 향상    
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
        </div>
            </div>

            
        </div>


      <div className='ex'>
        <div className='inner1200'>
          <h4 className='adTit'>스마트레터링의 <span>구축 사례</span></h4>
          <p className='adSubTit'>많은 기관에서 스마트레터링을 사용하고 있습니다.
          </p>
          <div className='buttonBox buttonBox2'>
            <button onClick={prevButton} className='prevButton'> <FontAwesomeIcon icon={faAngleLeft }/> </button>
            <button onClick={nextButton} className='nextButton'><FontAwesomeIcon icon={faAngleRight} /></button>
          </div>
            <Swiper
          // centeredSlides={true}
          slidesPerView={2}
          spaceBetween={30}
          // pagination={{
          //   clickable: true,
          // }}
          breakpoints={{
            982:{
              slidesPerView:3.3,
              spaceBetween:30
            },
          }}
          modules={[Navigation, EffectFade, Pagination, Autoplay]}
          className="mySwiper"
          loop={true}
          speed={800}
          navigation={true}
          // autoplay={{delay:2000 , disableOnInteraction:true}}
          autoHeight={true} 
          touchRatio={true} 
          onSwiper={(s) => {
            setSwiper(s);
          }}
        >
            
          {itemList}
          
          </Swiper>


        </div>

        {
      	modal == true ? <Modal modal={modal} setModal={setModal} items={items} modalImg={modalImg} /> : null 
        }

      </div>

      <div className='gs'>
        <div className='inner1200'>
        <h4 className='adTit'>스마트레터링의 <span>GS 인증</span></h4>
        <p className='adSubTit'>소프트웨어 품질인증서 입니다.
          </p>
        <div className='gsWrap'>
          <img src="/assets/SLgs.png"/>

            <div className="gsTable">
              <h5>GS 인증번호: 22-0446</h5>
              <table>
                <thead>
                  <tr>
                    <th>구분</th>
                    <th>제품구성</th>
                    <th>식별번호</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>SW Package</td>
                    <td>스마트레터링 v1.0 1Package</td>
                    <td>24741980</td>
                  </tr>
                  <tr>
                    <td>User License</td>
                    <td>스마트레터링 v1.0 1User</td>
                    <td>24759314</td>
                  </tr>
                  <tr>
                    <td>CH License</td>
                    <td>스마트레터링 v1.0 1CH</td>
                    <td>24759313</td>
                  </tr>
                  <tr>
                    <td>PBX I.F License</td>
                    <td>스마트레터링 v1.0 PBX I.F License</td>
                    <td>24759312</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div className='patent'>
        <div className='inner1200'>
        <h4 className='adTit patentTit adTit_pc'>스마트레터링의 <span>특허증</span>과 <span>디자인등록증</span></h4>
        <h4 className='adTit patentTit adTit_mobile'>스마트레터링의 <span>특허증</span>과 <br /><span>디자인등록증</span></h4>
        {/* <p className='adSubTit'>소프트웨어 품질인증서 입니다.
        </p> */}

          <div className='patentWrap'>
            <span>특허증</span>
            <ul>
              <li>
                <img src="/assets/patent/patent1.jpg" />
              </li>
              <li>
                <img src="/assets/patent/patent2.jpg" />
              </li>
              <li>
                <img src="/assets/patent/patent3.jpg" />
              </li>
            </ul>

            <span>디자인등록증</span>
            <ul>
              <li>
                <img src="/assets/patent/design1.jpg" />
              </li>
              <li>
                <img src="/assets/patent/design2.jpg" />
              </li>
              <li>
                <img src="/assets/patent/design3.jpg" />
              </li>
              <li>
                <img src="/assets/patent/design4.jpg" />
              </li>
              <li>
                <img src="/assets/patent/design5.jpg" />
              </li>
              <li>
                <img src="/assets/patent/design6.jpg" />
              </li>
              <li>
                <img src="/assets/patent/design7.jpg" />
              </li>
              <li>
                <img src="/assets/patent/design8.jpg" />
              </li>
              <li>
                <img src="/assets/patent/design9.jpg" />
              </li>
              <li>
                <img src="/assets/patent/design10.jpg" />
              </li>
              <li>
                <img src="/assets/patent/design11.jpg" />
              </li>
            </ul>
          </div>
        </div>
      </div>

    </div>
  
  )
}


export default Solutionsmart;