import React from 'react';
import Nav from '../components/Nav';
import style from './About.module.css'
import Submenu from './Submenu';
import Footer from '../components/Footer';
import { useRef , useState, useEffect } from "react";
import './Solution.css';
import { Routes, Route, Link } from 'react-router-dom';
import Solutionsmart from './Solutionsmart';
import Solutionaurum from './Solutionaurum';
import Solutionpersonal from './Solutionpersonal';
import Solutionipcc from './Solutionipcc';
import Solutionipt from './Solutionipt';
import Solutionmaintenance from './Solutionmaintenance';
import solution from './Solution.module.css';

function Solution (props){


  const subMenu = [
    {
      id:1,
      link:'/solution/solutionsmart',
      title:'스마트레터링',
    },
    {
      id:2,
      link:'/solution/solutionaurum',
      title:'아우룸스위트',
    },
    {
      id:3,
      link:'/solution/solutionpersonal',
      title:'퍼스널레터링',
    },
    {
      id:4,
      link:'/solution/solutionipt',
      title:'IPT',
    },
    {
      id:5,
      link:'/solution/solutionipcc',
      title:'IPCC',
    },
    {
      id:6,
      link:'/solution/solutionmaintenance',
      title:'솔루션 유지보수',
    },
    
  ]

  const [activeList, setActiveList] = useState();

  return(
    <div className='aboutback'>
    <Nav />
    <div className='solutionWrap'>
      <div id={solution.aboutBg}></div>
      <div className="titBox">
            <h2>제품 및 솔루션</h2>
            <span>다옴솔루션은 끊임없는 변화와 혁신으로 <br /> IPT/IPCC 분야에서 최고로 인정받는 선도기업입니다.</span>
      </div>
      </div>
      {/* <div className='inner1200'> */}
          <div id="lnb" className="inner lnb1">
            <div className='inner1200'>   
              <Submenu subMenu={subMenu} activeList={activeList} setActiveList={setActiveList}/>
            </div>
          </div>
        {/* </div> */}
      
        <Routes>
          <Route path="/solutionsmart" element={<Solutionsmart />} />
          <Route path="/solutionaurum" element={<Solutionaurum />} />
          <Route path="/solutionpersonal" element={<Solutionpersonal />} />
          <Route path="/solutionipt" element={<Solutionipt />} />
          <Route path="/solutionipcc" element={<Solutionipcc />} />
          <Route path="/solutionmaintenance" element={<Solutionmaintenance />} />
        </Routes>
  <Footer />
  </div>
  
  )
}


export default Solution;