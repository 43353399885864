import React from 'react';
import Nav from '../components/Nav';
import style from './About.module.css'
import Submenu from './Submenu';
import Footer from '../components/Footer';
import { useRef , useState, useEffect } from "react";
import { Routes, Route, Link } from 'react-router-dom';
import './Support.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCircleInfo} from "@fortawesome/free-solid-svg-icons";

const {kakao} = window;
function KakaoMap (props){

  useEffect(()=>{

    var container = document.getElementById('map'); //지도를 담을 영역의 DOM 레퍼런스
    var options = { //지도를 생성할 때 필요한 기본 옵션
      center: new kakao.maps.LatLng(37.474824800353, 126.89032459934), //지도의 중심좌표.
      level: 3 //지도의 레벨(확대, 축소 정도)
    };
    
    var map = new kakao.maps.Map(container, options); //지도 생성 및 객체 리턴
        //마커가 표시 될 위치
        let markerPosition = new kakao.maps.LatLng(
          37.474824800353,
          126.89032459934
       // 이부분의 위,경도 또한 가끔 가는 카페입니다.. 놀라지마시길..!
        );
    
        // 마커를 생성
        let marker = new kakao.maps.Marker({
          position: markerPosition,
        });
    
        // 마커를 지도 위에 표시
        marker.setMap(map);
  }, [])



  return(
    <div id="map"></div>



  
  )
}


export default KakaoMap;