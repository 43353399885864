import React from 'react';
import Nav from '../components/Nav';
import style from './About.module.css'
import Submenu from './Submenu';
import Footer from '../components/Footer';
import { useRef , useState, useEffect } from "react";
import { Routes, Route, Link } from 'react-router-dom';
import './Recruit.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faPhone, faDesktop} from "@fortawesome/free-solid-svg-icons";
import './Support.css';

function Solutionaurum (props){

  const [activeTab, setActiveTab] = useState(0);
  const [index, setIndex] = useState(0);

  const func = [
    {
      id:0,
      title:'자동안내',
      img:'/assets/solution/자동안내.png',
      content:`
- WEB 기반 GUI(Graphic User Interface) 프로그램 제공 \n
- ARS 대표전화 번호 음성 자동 안내 서비스 기능 제공 \n
- 365일 24시간 음성 안내 및 콜백 서비스 기능 제공 \n
- 다양한 통계 및  Multi ARS 서비스 기능 제공 \n
- TTS/CTI/기간계 DB 연동 기능제공 \n
- ARS 인입호   CID 조회  검색 기능 제공
`
    },
    {
      id:1,
      title:'컬러링',
      img:'/assets/solution/컬러링.png',
      content:`
- 개인별, 부서별, 날짜별, 월별 등 다양한 컬러링 설정 기능 제공 \n
- 컬러링 음원 송출 우선 순위 설정 기능 제공 \n
- TTS 탑재 및 BGM(배경음악) 믹싱 기능 제공 \n
- 다양한 통계 조회 및 그래프 생성 기능 제공 \n
- WEB 기반 GUI(Graphic User Interface) 프로그램 제공
      `
    },
    {
      id:2,
      title:'녹취',
      img:'/assets/solution/녹취.png',
      content:
      `
- WEB 기반 GUI(Graphic User Interface)프로그램 제공 \n
- ARS 대표전화 번호 음성 자동 안내 서비스 기능 제공 \n
- 365일 24시간 음성 안내 및 콜백 서비스 기능 제공 \n
- 다양한 통계 및  Multi ARS 서비스 기능 제공\n
- TTS/CTI/기간계 DB 연동 기능제공\n
- ARS 인입호 CID 조회  검색 기능 제공
      `
    },
  ]


  const funcList = func.map((func, index)=>{
    return(
          <li key={func.id}
          style={{border : activeTab == func.id ? "1px solid #ff4938" : ""}}
          >
            <a
          style={{color : activeTab == func.id ? "#ff4938" : ""}}
          data-active={activeTab == func.id ? "true" : "false"}
          onClick={(e)=>{
            setActiveTab(func.id);
            setIndex(func.id)

          }}
        >{func.title}</a></li>
    )
  })



  return(
    <div id="sb2">
        {/* <h3 class="pageTit">아우룸스위트</h3> */}

        <div className='product'>
        <div className='inner1200'>
          <div className='titleWrap'>
              <h1 className='solutionTit'>Aurum Suite</h1>
              <p className='solutionText'>
          아우룸스위트 (자동안내/컬러링/녹취) v1.0 제품은 <br />
          IP PBX와 SIP 표준 프로토콜로 연동 구성하여 대표전화번호에 365일 24시간 ARS 음성 자동안내 서비스 제공기능은 물론
          개별 내선전화번호에 대하여 부서별, 날짜별, 시간대별, 개인별 통화 연결음(컬러링) 서비스를 제공 합니다.<br /><br />
           또한 외부 고객과 통화 중에 선택적으로 통화 내용을 녹취 할 수 있는 선택 녹취, 모든 통화 내용을 녹음 할 수 있는 전수 녹취 기능을 제공 하며
          WEB 기반 프로그램을 통하여 녹취 파일을 조회/청취/다운로드 할 수 있는 음성 복합 통신 소프트웨어 입니다.         
          </p>
            </div>
        </div>
      </div>


      <div className='ARSFuncWrap'>
          <div className='inner1200'>
            <h4 className='adTit'>아우룸스위트의 <span>기능</span></h4>

            <div className='funcList'>
              <ul>
                {funcList}
              </ul>
            </div>

            <ul className='funcItemList'>
              {func.filter(item => index === item.id).map(item => (
                <li key={item.id}>
                <div className='funcItem'>
                  <div className='funcContent'>
                    <h5 className='itemTit'>{item.title}</h5>
                    <p>
                      {item.content}
                    </p>
                  </div>
                  <div className='funcImg'>
                    <img src={item.img} />
                  </div>
                </div>
              </li>
                
              ))}
              {/* <li>
                <div className='funcItem'>
                  <img src="/assets/자동안내.png" />
                  <p>
                    <h5>자동안내</h5>
                  WEB 기반 GUI(Graphic User Interface)프로그램 제공<br/>
                  ARS 대표전화 번호 음성 자동 안내 서비스 기능 제공<br/>
                  365일 24시간 음성 안내 및 콜백 서비스 기능 제공<br/>
                  다양한 통계(Pivot 기능 제공_통계항목별 마우스   드래그) 및  Multi ARS 서비스 기능 제공<br/>
                  TTS/CTI/기간계 DB 연동 기능제공, ARS 인입호   CID 조회  검색 기능 제공
                  </p>
                </div>
              </li>

              <li>
                <div className='funcItem'>
                  <p>
                  <h5>컬러링</h5>
                  개인별, 부서별, 시간대별, 날짜별, 월별, 요일별 등 다양한 컬러링 설정 기능 제공<br />
                  컬러링 음원 송출 우선 순위 설정 기능 제공<br/>
                  TTS 탑재 및 BGM(배경음악) 믹싱 기능 제공<br/>
                  다양한 통계(Pivot 기능 제공_통계항목별 마우스 드래그) 조회    및   그래프 생성 기능 제공<br/>
                  WEB 기반 GUI(Graphic User Interface)프로그램 제공
                  </p>
                  <img src="/assets/컬러링.png" />
                </div>
              </li>

              <li>
                <div className='funcItem'>
                  <img src="/assets/자동안내.png" />
                  <p>
                  <h5>녹취</h5>
                  WEB 기반 GUI(Graphic User Interface)프로그램 제공<br/>
                  ARS 대표전화 번호 음성 자동 안내 서비스 기능 제공<br/>
                  365일 24시간 음성 안내 및 콜백 서비스 기능 제공<br/>
                  다양한 통계(Pivot 기능 제공_통계항목별 마우스   드래그) 및  Multi ARS 서비스 기능 제공<br/>
                  TTS/CTI/기간계 DB 연동 기능제공, ARS 인입호   CID 조회  검색 기능 제공
                  </p>
                </div>
              </li> */}
            </ul>

          
          </div>
      </div>


      <div className='gs'>
        <div className='inner1200'>
        <h4 className='adTit'>아우룸스위트의 <span>GS 인증</span></h4>
        <p className='adSubTit'>소프트웨어 품질인증서 입니다.
          </p>
        <div className='gsWrap'>
          <img src="/assets/gs.png"/>

            <div className="gsTable">
              <h5>GS 인증번호: 22-0553</h5>
              <table>
                <thead>
                  <tr>
                    <th>구분</th>
                    <th>제품구성</th>
                    <th>식별번호</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>SW</td>
                    <td>아우룸스위트(자동안내/컬러링/녹취) v1.0</td>
                    <td>24820048</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    
  </div>
  
  )
}


export default Solutionaurum;