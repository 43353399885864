import React from 'react';
import './Solution.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMobile } from "@fortawesome/free-solid-svg-icons";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import {faAngleRight} from "@fortawesome/free-solid-svg-icons";
import {faAngleLeft} from "@fortawesome/free-solid-svg-icons";

import { useRef , useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSwiper } from 'swiper/react';
import { Autoplay, EffectFade, Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/effect-coverflow";


function Solution (){

  const [swiper, setSwiper] = useState(null);

  const nextButton = () => {
    swiper.slideNext();
  };

  const prevButton = () => {
    swiper.slidePrev();
  };

  const solutions = [
    {
      id:0,
      img:'assets/solution3.png',
      title:'아우룸스위트',
      text:'아우룸스위트 (자동안내/컬러링/녹취) v1.0 제품은 IP PBX와 SIP 표준 프로토콜로 연동 구성하여 대표전화번호에 365일 24시간 ARS 음성 자동안내 서비스 제공기능은 물론 개별 내선전화번호에 대하여 부서별, 날짜별, 시간대별, 개인별 통화 연결음(컬러링) 서비스를 제공 합니다.',
      link:`/solution/solutionaurum`
    },
    {
      id:1,
      img:'assets/solution1.png',
      title:'스마트레터링',
      text:`스마트 레터링 v1.0 시스템은
      내부 직원이 고객 또는 민원인에게 전화를 걸 경우
      수신자 스마트 폰에 지자체 또는 공공기관 이미지와 해당 부서 정보를 팝업 표시 하여 주는 시스템으로
      보이스 피싱 예방 및 각종 홍보 안내를 할 수 있는 서비스 입니다.`,
      link:`/solution/solutionsmart`
    },
    {
      id:2,
      img:'assets/solution2.png',
      title:'퍼스널레터링',
      text:'퍼스널 레터링 솔루션은 개인간 스마트폰 끼리 레터링 서비스를 제공 할 수 있는 제품으로 자사 솔루션 앱을 설치, 회원 가입 후 요금제를 선택 하시면 사용 하실 수 있습니다.',
      link:`/solution/solutionpersonal`
    },
    {
      id:3,
      img:'assets/solution4.png',
      title:'솔루션유지보수',
      text:`Call center 솔루션, 상담AP 및 IPT 솔루션 유지관리 업무를 전문적으로 수행하며, 신속한 장애처리와 이력관리, 사전 장애 요소를 진단/조치를 통해 최적의 시스템 유지관리로 고객의 만족을 높일 수 있습니다.`,
      link:`/solution/solutionmaintenance`
    },
  ]

  const solutionList = solutions.map((solution, index)=>{
    
    return (
      <SwiperSlide key={solution.id}>
      <div className='solutions' onClick={()=>{
                window.location.href = solution.link;
              }}>
          <div className='solution-list-text'>
            <img src={solution.img}></img>
            <span>{solution.title}</span>
          </div>
        <div className='hoverTextWrap'>
          <div className='hoverText'>
              <h3 className='solutionTitle'>{solution.title}</h3>
              <p>
              {solution.text}
              </p> 
              <button className='overView solutionOverView'><a>Overview<FontAwesomeIcon icon={faArrowRightLong}  /></a></button>
            </div>
            </div>
        </div>
      </SwiperSlide>
    )
  })



  return(
    <div className='solutionback'>
      <div className="inner1200">
        {/* <div className='solution-flex-wrap'>
          <div className='solution-title-wrap'>
            <span className='why'>Why DaomSolution?</span>
            <h2>다옴솔루션을 <br/> 선택해야될 이유!</h2>
            <h5>다옴솔루션의 다양한 솔루션을 만나보세요.</h5>
            <div className='buttonWrap'>
              <button className='overview'>Solution Overview<FontAwesomeIcon icon={faArrowRightLong}  /></button>
            </div>
          </div>

          <div className='solution-list-wrap'>
          <Swiper
          slidesPerView={3}
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          className="mySwiper"
          loop={true}
        >
            
          {solutionList}
            
          </Swiper>
          </div>
        </div> */}
              <div className='FlexWrap'>
        {/* <div className='left'>
          <img src='/assets/@sample2.jpg' /> 
        </div> */}

        <div className='titleBox'>
          {/* <span className='why'>Solution</span>  */}
          <h2>다옴솔루션의 <span className='Montserrat'>Solution</span></h2>

          {/* <div className='buttonWrap'>
            <button className='overview'>Solution Overview<FontAwesomeIcon icon={faArrowRightLong}  /></button>
          </div>  */}
        </div>

        <div className='contentBox'>
          <Swiper
          centeredSlides={true}
          slidesPerView={1.3}
          spaceBetween={60}
          breakpoints={{
            800:{
              slidesPerView:2,
              spaceBetween:110
            },

            1000:{
              slidesPerView:2.5,
              spaceBetween:110
            },

            1250:{
              slidesPerView:3,
              spaceBetween:110
            },



            1600:{
              slidesPerView:4,
              spaceBetween:110
            }

          }}
  
          // pagination={{
          //   clickable: true,
          // }}
          modules={[Navigation, EffectFade, Pagination,
             Autoplay
            ]}
          className="mySwiper"
          loop={true}
          speed={800}
          navigation={true}
          autoplay={{delay:4000 , disableOnInteraction:true}}
          autoHeight={true}
          touchRatio={true}
          onSwiper={(s) => {
            setSwiper(s);
          }}
        >
            
          {solutionList}
          
          <div className='buttonBox'>
            <button onClick={prevButton} className='prevButton'> <FontAwesomeIcon icon={faAngleLeft }/> </button>
            <button onClick={nextButton} className='nextButton'><FontAwesomeIcon icon={faAngleRight} /></button>
          </div>
          </Swiper>


        </div>
      </div>
      </div>
    </div>
  )
}


export default Solution;