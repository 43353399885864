import React, { useEffect } from 'react';
import Nav from '../components/Nav';
import style from './About.module.css'
import { NavLink } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';

function Submenu (props){
  const subMenuList = props.subMenu.map((subMenu, index)=>{
    return (
      
    <li 
    key={index}
    // className={props.activeList === subMenu.id ? "active":""}
    onClick={()=>{
      
      props.setActiveList(subMenu.id);
    }

    }><NavLink to ={subMenu.link}>{subMenu.title}</NavLink></li>
    )
    
  })

  const location = useLocation();


  return(
    <ul  id={location.pathname === '/solution/solution1' || location.pathname === '/solution/solution2' || location.pathname === '/solution/solution3' || location.pathname === '/solution/solution4' || location.pathname === '/solution/solution5' || location.pathname === '/solution/solution6' ? 'm1': ''}>
      {/* <li className = {props.activeList[0]? "active" : ""} onClick={()=>{
        props.setActiveList([true,false,false,false,false]);
      }

      }><Link to ="/about">{props.subMenu[0].title}</Link></li>
      <li className = {props.activeList[1]? "active" : ""} onClick={()=>{
        props.setActiveList([false,true,false,false,false]);
      }

      }><Link to ="/about/history">{props.subMenu[1].title}</Link></li>
      <li className = {props.activeList[3]? "active" : ""} onClick={()=>{
        props.setActiveList([false,false,false,true,false]);
      }

      }><Link to ="/about/partner">{props.subMenu[2].title}</Link></li>
      <li className = {props.activeList[4]? "active" : ""}  onClick={()=>{
        props.setActiveList([false,false,false,false, true]);
      }

      }><Link to ="/about/vision">{props.subMenu[3].title}</Link></li> */}
      {subMenuList}
  </ul>
  
  )
}


export default Submenu;