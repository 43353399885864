import React from 'react';
import Nav from '../components/Nav';
import style from './About.module.css'
import Submenu from './Submenu';
import Footer from '../components/Footer';
import { useRef , useState, useEffect } from "react";
import './Recruit.css';
import { Routes, Route, Link, useLocation } from 'react-router-dom';
import Support1 from './Support1';
import Support2 from './Support2';
import newsStyle from './News.module.css';
import './News.css'
import ReportTemplate from './ReportTemplate';
import Pagination from './Pagination';

function Report (props){
  
  let news = [
    {
      id:10,
      title:`평택시, 관공서 사칭 전화 예방 '스마트 레터링 서비스' 도입`,
      img:`<img src='/assets/news/평택.jpg'} alt="뉴스이미지" />`,
      date:`2024-08-19`,
      source:`https://www.fnnews.com/news/202408191415057135`,
      desc:`【파이낸셜뉴스 평택=장충식 기자】 경기도 평택시는 관공서 사칭 전화사기 피해를 예방하고, 행정 전화의 신뢰성을 높이기 위해 '스마트 레터링 서비스'를 도입해 시범운영한다고 19일 밝혔다.

이에 따라 평택시가 시민에게 전화를 걸 때 시민의 스마트폰 화면에 평택시청 이미지와 함께 부서명과 행정 전화번호가 표시되고, 통화 종료 시에는 최신 시정 안내 및 행사 정보 등을 제공한다.

해당 서비스는 '보이는 ARS 서비스 공유 앱'이 설치된 안드로이드 기반 스마트폰에 한해 제공되며, 그 외 스마트폰 사용자에 대해서는 부재 등 통화연결이 이루어지지 않았을 시 카카오 알림톡을 통한 전화 알림 서비스를 제공할 예정이다.
      
시민은 전화를 안심하고 받을 수 있을 뿐만 아니라 시책 및 축제·행사 등의 정보를 빠르고 쉽게 알게 됨으로써 다양한 혜택을 받을 것으로 기대되고 있다.
      
시는 올해 관내 읍면동 행정복지센터와 시민과의 통화량이 많은 부서를 대상으로 운영하고, 향후 전체 부서로 운영을 확대할 예정이다.`
    },
    {
      id:9,
      title:`경산시, '행정전화 안심하고 받으세요!'`,
      img:`<img src='/assets/news/경산.jpg'} alt="뉴스이미지" />`,
      date:`2024-08-12`,
      source:`http://www.dailydgnews.com/mobile/article.html?no=192775`,
      desc:`(데일리대구경북뉴스=황지현 기자)경산시가 민원인에게 전화를 걸 때 민원인의 스마트폰 화면에 시청 이미지와 함께 부서 행정전화 표시 등 발신자 정보를 표시해 주는 ‘스마트 레터링 서비스’를 도입했다.

시는 시범운영 기간을 거쳐 8월 12일부터 시청에서 민원인에게 통화 시 스마트폰 화면에 시청 이미지와 부서 정보를 제공하고, 통화 종료 시에는 시청 누리집 연결을 통해 경산시의 다양한 행사 정보와 시정 안내 서비스 등을 제공하는 ‘스마트 레터링 서비스’를 시행할 계획이다.
      
경산시는 행정전화를 스팸이나 보이스피싱으로 오인해 전화를 받지 않아 민원행정 처리가 지연됐던 부분을 해소하고, 행정전화 신뢰도 향상은 물론 다양한 시정 홍보를 할 수 있게 됐다.
      
스마트 레터링 서비스는 보이는 ARS 서비스 공유 앱이 설치된 안드로이드 기반의 스마트폰에 한해 서비스가 제공된다. ARS 서비스 공유 앱이 설치되지 않은 스마트폰과 보안정책상 미지원되는 아이폰 사용자를 대상으로는 부재중 통화에 대해 카카오 알림톡 서비스나 문자서비스를 통해 시청 부서정보와 발신자 번호, 시청 누리집 링크를 제공하게 된다.
      
시는 스마트 레터링 서비스 도입으로 그동안 시청으로부터 걸려 온 전화의 정보 미확인에 따른 미수신으로 민원행정 처리가 지연되었던 부분을 상당부문 해소하고, 민원인들도 안심하고 전화를 받을 수 있어 신속한 행정 처리 및 민원업무 편의성이 증대될 것으로 기대하고 있다.
      
동시에 시는 통화 종료 후 민원인 스마트폰에 다양한 시정소식(행사, 축제, 관광, 강연 등) 안내로 시정 신뢰도 향상과 시정 홍보 효과도 극대화될 것으로 내다보고 있다.
 
조현일 경산시장은 “스마트 레터링 서비스로 시민들을 위한 다양한 시정소식을 빠르게 전달할 수 있을 것으로 기대한다.”며“앞으로도 민원인의 편의 증진을 위한 다양한 아이디어를 적극적으로 시정에 반영해 나가겠다.”고 말했다.`
    },
    {
      id:8,
      title:`고창군, ‘스마트 레터링 서비스’ 도입`,
      img:`<img src='/assets/sampleImg.png'} alt="뉴스이미지" />`,
      date:`2024-06-09`,
      source:`https://www.jjn.co.kr/news/articleView.html?idxno=973829`,
      desc:`고창군이 관공서를 사칭한 전화사기 피해를 예방하고, 행정전화의 신뢰성을 
높이기 위하여 ‘스마트 레터링 서비스’를 도입한다.

군이 민원인에게 전화를 걸 때 민원인의 스마트폰 화면에 고창군 이미지와 함께 부서 이름 및 전화번호가 표시되고, 통화가 끝나면 군청 누리집 카드뉴스와 관광 정보 안내 등이 제공된다.
      
고창군은 오는 10일까지 운영 테스트를 거쳐 11일부터 정식으로 서비스를 제공할 예정이며, 이 서비스는 안드로이드 스마트폰에 설치된 ‘보이는 ARS 서비스 공유 앱’을 활용하여 제공된다.
      
군은 해당 서비스가 도입되면 민원인이 행정 전화번호를 보이스피싱과 스팸 전화로 오인해 받지 않아 민원 처리가 지연되는 문제를 해소할 수 있고, 군민들이 군에서 걸려 오는 전화를 안심하고 받을 수 있어 신속한 행정 지원이 가능해질 것으로 기대하고 있다.
      
유창형 행정지원과장은 “앞으로도 최신 정보통신 기술을 활용하여 군민들이 편안하고 안심할 수 있는 민원 행정서비스를 이용할 수 있도록 다양한 아이디어를 적극 반영해 나가겠다”고 말했다.`
    },

    {
      id:7,
      title:`'행정전화 안심하세요'… 고창군, 보이스피싱 예방 '레터링' 서비스`,
      img:`<img src='/assets/news/news-4.jpg'} alt="뉴스이미지" />`,
      date:`2024-06-07`,
      source:`https://www.news1.kr/articles/5440016`,
      desc:`전북특별자치도 고창군이 관공서를 사칭한 전화사기 피해를 예방하고, 행정전화의 신뢰성을 높이기 위해 ‘스마트 레터링 서비스’를 도입한다고 7일 밝혔다.
군이 민원인에게 전화를 걸 때 민원인의 스마트폰 화면에 고창군 이미지와 함께 부서 이름 및 전화번호가 표시된다. 통화가 끝나면 군청 누리집 카드뉴스와 관광 정보 안내 등을 제공할 계획이다.
      
이 서비스는 이달 10일까지 운영 테스트를 거쳐 11일부터 정식 서비스를 제공하며 안드로이드 스마트폰에 설치된 ‘보이는 ARS 서비스 공유 앱’을 활용하여 제공된다.
애플 정책상 미지원되는 아이폰과 '보이는 ARS 서비스 공유 앱‘이 설치되지 않은 안드로이드 스마트폰에 대해서는 카카오 알림톡 서비스를 통해 ‘부재중 통화 알림’ 서비스가 제공될 예정이다. 사용을 원치 않는 민원인은 ‘수신거부’ 버튼을 통해 서비스를 중단할 수 있다.

고창군은 해당 서비스가 도입되면 민원인이 행정 전화번호를 보이스피싱과 스팸 전화로 오인해 받지 않아 민원 처리가 지연되는 문제를 해소하고 또 전화를 안심하고 받을 수 있어 신속한 행정 지원이 가능해질 것으로 기대한다.

유창형 행정지원과장은 “앞으로도 최신 정보통신 기술을 활용해 군민들이 편안하고 안심할 수 있는 민원 행정서비스를 이용할 수 있도록 다양한 아이디어를 적극 반영하겠다”고 말했다.`
    },
    {
      id:6,
      title:`"보이스피싱 걱정 마세요" 전주시, 스마트 레터링 시스템`,
      img:`<img src='/assets/news/뉴시스.jpg'} alt="뉴스이미지" />`,
      date:`2024-05-19`,
      source:`https://n.news.naver.com/article/003/0012553459`,
      desc:`[전주=뉴시스] 윤난슬 기자 = 앞으로 전북 전주시청에서 걸려 온 전화를 전화금융사기(보이스피싱) 피해 걱정 없이 받을 수 있다.

시는 민원인에게 전화를 걸 때 민원인의 스마트폰 화면에 시청 이미지와 함께 부서 행정 전화 표시 등 발신자 정보를 표시해 주는 '스마트 레터링 서비스'를 도입한다고 19일 밝혔다.
      
시는 시범운영 기간을 거쳐 오는 20일부터 시청에서 민원인에게 통화 시 스마트폰 화면에 시청 이미지와 부서 행정 전화 표시를 제공한다.
      
통화 종료 시에는 시청 누리집 연결을 통해 전주시의 다양한 행사 정보와 시정 안내 서비스 등도 나온다.
      
이 서비스는 보이는 ARS 서비스 공유 앱이 설치된 안드로이드 기반의 스마트폰에 한해 우선 서비스가 제공된다.
      
시는 ARS 서비스 공유 앱이 설치되지 않은 스마트폰과 보안 정책상 미지원되는 아이폰 사용자에 대해서는 부재중 통화에 대해 카카오 알림톡 서비스를 통해 시청 부서 정보와 발신자 번호, 시청 누리집 링크를 제공하도록 추가 서비스를 개발하고 있다.
      
이 서비스가 도입되면 그동안 시청으로부터 걸려 온 전화를 보이스피싱 또는 스팸 전화로 오인해 받지 않아 민원 행정 처리가 지연됐던 부분을 상당 부문 해소하고, 민원인들도 안심하고 전화를 받을 수 있을 것으로 기대하고 있다.

정문구 전주시 정보화정책과장은 "시민들을 위한 다양한 공모사업, 지원 사업, 의견 수렴, 수강 안내, 시정 안내 등을 스마트 레터링 서비스로 홍보할 수 있게 돼 시민들에게 다양한 정보를 빠르게 전달할 수 있을 것으로 기대한다"면서 "앞으로도 민원인의 편의 증진을 위한 다양한 아이디어를 적극적으로 반영해 나가겠다"고 말했다.
      
윤난슬 기자(yns4656@newsis.com)`
    },
    {
      id:5,
      title:`전주시, 전북 최초로 발신자표시 서비스 ‘스마트 레터링 시스템’ 구축`,
      img:`<img src='/assets/sampleImg.png'} alt="뉴스이미지" />`,
      date:`2024-05-17`,
      source:`http://www.jeonbuktimes.co.kr/m/view.asp?idx=181238`,
      desc:`전주시민들은 앞으로 전주시청에서 걸려 온 전화를 보이스피싱 피해 걱정 없이 받을 수 있다.

이는 전주시가 민원인에게 전화를 걸 때 민원인의 스마트폰 화면에 시청 이미지와 함께 부서 행정 전화 표시 등 발신자 정보를 표시해주는 ‘스마트 레터링 서비스’를 도입하기 때문이다.
      
시는 시범운영 기간을 거쳐 오는 20일부터 시청에서 민원인에게 통화 시 스마트폰 화면에 시청 이미지와 부서 행정전화 표시를 제공하고, 통화 종료 시에는 시청 누리집 연결을 통해 전주시의 다양한 행사 정보와 시정 안내 서비스 등을 제공하는 ‘스마트 레터링 서비스’를 시행할 계획이라고 16일 밝혔다.
      
스마트 레터링 서비스는 보이는 ARS 서비스 공유 앱이 설치된 안드로이드 기반의 스마트폰에 한해 우선 서비스가 제공된다.
      
시는 ARS 서비스 공유 앱이 설치되지 않은 스마트폰과 보안정책상 미지원되는 아이폰 사용자에 대해서는 부재중 통화에 대해 카카오 알림톡 서비스를 통해 시청 부서 정보와 발신자 번호, 시청 누리집 링크를 제공하도록 추가 서비스를 개발하고 있다.
      
시는 이 서비스가 도입되면 그동안 시청으로부터 걸려 온 전화를 보이스피싱 또는 스팸 전화로 오인해 받지 않아 민원 행정 처리가 지연되었던 부분을 상당부문 해소하고, 민원인들도 안심하고 전화를 받을 수 있어 신속한 행정 처리 및 민원 업무 처리가 가능해질 것으로 기대하고 있다.
      
동시에 시는 통화 종료 후 에필로그 화면에서 시청에서 전송하는 URL 연결을 통해 다양한 콘텐츠(행사 안내, 시정 안내 등)가 제공되는 만큼 시정 신뢰도 향상과 시정 홍보 효과도 극대화될 것으로 내다보고 있다.
      
시는 새롭게 시행되는 스마트 레터링 시스템을 보안성과 안정성이 검증된 국내 최초 GS 인증 제품으로 도입해 안정성도 확보했다.`
    },
    {
      id:4,
      title:`전주시, 민원인에게 발신자 정보 표시 서비스`,
      img:`<img src='/assets/news/news-3.jpg'} alt="뉴스이미지" />`,
      video:`<div class="">
			<div> 
				<iframe width="100%" height="100%" src="https://news.kbs.co.kr/news/pc/view/embedVideo.do?vodUrl=/newsmp4/jeonju/news7/2024/05/16/1500k/260_7965114.mp4&imgUrl=https://news.kbs.co.kr/data/news/title_image/newsmp4/jeonju/news7/2024/05/16/260_7965114.jpg&captionYn=N" title="video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; web-share" allowfullscreen></iframe>
 			</div>
		</div>`,
      date:`2024-05-16`,
      source:`https://news.kbs.co.kr/news/pc/view/view.do?ncd=7965114&ref=A`,
      desc:`전주시가 민원인에게 전화를 걸 때 민원인의 스마트폰 화면에 시청 이미지와 부서 전화번호 등 발신자 정보 표시 서비스를 제공합니다.

전주시는 민원인들이 전화 금융사기 등을 걱정하지 않고 행정 전화를 수 있도록 오는 20일부터 발신자 정보를 제공하고, 전화를 끊은 뒤 전주시 누리집 연결을 통해 다양한 행사 정보도 전달할 계획입니다.`
    },
    {
      id:3,
      title:`전주시, 민원인에 전화 걸 때 '부서행정 전화' 정보 표시한다`,
      img:`<img src='/assets/news/전북일보.jpg'} alt="뉴스이미지"/>`,
      date:`2024-05-16`,
      source:`https://www.jjan.kr/article/20240516580196`,
      desc:`전주시가 관공서 사칭 전화사기 피해를 예방하고 행정 처리의 신속성과 편의를 높이기 위해 '스마트 레터링' 서비스를 도입했다. 
시에 따르면 오는 20일부터 전주시가 민원인에게 전화를 걸 때 민원인의 스마트폰 화면에 시청 이미지와 함께 부서 행정 전화 표시 등 발신자 정보가 표시된다.
통화 종료 시에는 시청 누리집 연결을 통해 전주시의 다양한 행사 정보와 시정 안내 서비스 등을 제공하는 ‘스마트 레터링 서비스’를 시행할 계획이다.

이 서비스는 시스템 안정성이 검증된국내 최초 GS 인증 제품으로 도입했으며 '보이는 ARS 서비스 공유' 앱이 설치된 안드로이드 기반의 스마트폰에 한해 제공된다. 그외의 경우에는 부재중 통화에 대해 카카오 알림톡 서비스를 활용, 시청 부서 정보와 발신자 번호와 시청 누리집 링크를 제공하도록 추가 서비스를 개발하고 있다.

시는 이 서비스가 도입되면 그동안 시청으로부터 걸려 온 전화를 보이스피싱 또는 스팸 전화로 오인해 받지 않아 민원 행정 처리가 지연되었던 부분을 상당부문 해소하고, 민원인들도 안심하고 전화를 받을 수 있어 신속한 행정 처리 및 민원 업무 처리가 가능해질 것으로 기대하고 있다.

정문구 시 정보화정책과장은 “시민들을 위한 다양한 공모사업, 지원 사업, 의견 수렴, 수강 안내, 시정 안내 등을 스마트 레터링 서비스로 홍보할 수 있게 돼 시민들에게 다양한 정보를 빠르게 전달할 수 있을 것으로 기대하고 있다”면서 “앞으로도 민원인의 편의 증진을 위한 다양한 아이디어를 적극적으로 반영해 나가겠다”고 말했다.`
    },
    {
      id:2,
      title:`[전자신문] 다옴솔루션, '스마트레터링' 시장서 인기몰이`,
      img:`<img src='/assets/news/news-1.jpg'} alt="뉴스이미지"/>`,
      date:`2023-10-17`,
      source:`https://m.etnews.com/20231017000294`,
      desc:`다옴솔루션(대표 윤지경)이 내놓은 홍보플랫폼 '스마트레터링 솔루션'을 선보여 업계 이목을 끌고 있다. 
17일 업계에 따르면 다옴솔루션 스마트레터링 서비스는 경기도 양주시청, 서울 영등포구청, 부산 남구청, 서울 용산구청 등에 구축, 공급되며 시장에서 큰 반향을 일으키고 있다. 

솔루션은 지자체와 공공기관에서 전화 수·발신 시 보이스피싱 예방 등을 지원한다. 강연과 각종교육, 설문조사, 축제, 행사, 대관안내, 	지원금 신청, 각종 지원사업 등을 매일 지자체와 공공기관과 통화하는 민원인 스마트폰에 통화 전후로 팝업해 지원한다.

솔루션은 안드로이드 OS 스마트폰 (콜게이트 런처 설치 스마트폰) 기반 서비스 임에도 카카오 알림톡과 SMS와 연동해 레터링 미지원 스마트폰에도 부서명과 행정전화번호, 각종 축제 관련 정보를 표시해 지원한다.

윤지경 다옴솔루션 대표는 “서비스를 이용 중인 지자체에서는 스마트레터링 에필로그에 카드뉴스, 유튜브 영상, SNS 모음링크, 레터링 수신거부 기능 등을 자체 개발 적용해 서비스를 제공하며 민원인의 호평을 받고 있다”며 “양주시청에서는 월 21만건 레터링 서비스를 지원하는 등 우수한 성과를 거뒀댜”고 말했다. 이어 “스마트레터링을 통해 지자체와 공공기관의 전통적인 소통방식을 혁신하고 민원인들에게 새로운 경험을 제공할 것”이라며 “지난해 조달청 디지털서비스몰에 상용SW로 도입한 상태이며, 향후 공공과 민간 전영역을 아우르는 서비스로 고도화할 계획”이라고 덧붙였다.`
    },
    {
      id:1,
      title:`[경기/양주시] 도내 지방정부 최초 '스마트 행정전화 알림' 서비스 시행'시민과의 소통 플랫폼' 기대`,
      img:`<img src='/assets/news/news-2.jpg'} alt="뉴스이미지"/>`,
      date:`2023-04-10`,
      source:`https://www.localnewsroom.co.kr/news/articleView.html?idxno=1737`,
desc:`[한국지방정부신문=김지수 기자] 경기도 양주시(시장 강수현)가 경기도 지방정부 최초로 스마트 행정전화 알림 서비스를 도입하고, 행정전화에 대한 신뢰도와 민원행정 서비스 향상에 힘쓰고 있다. 스마트 행정전화 알림서비스는 시민들이 시청으로 전화를 발신, 수신하는 경우 스마트폰 화면에 시청 로고와 행정전화 부서 등이 자동으로 표시돼 수·발신 부서 정보를 알려주는 서비스이다.
이번 서비스는 시민들이 공무 목적으로 발신된 행정전화를 광고, 스팸 전화로 오인하고 전화를 거절하는 사례가 빈번하게 발생함에 따라 원활한 통화 연결과 행정 처리가 지연되는 상황을 해소하기 위해 마련했다.

통화 수·발신 시 민원인의 스마트폰 화면에 행정전화 수·발신번호와 부서정보가 제공되며, 통화 종료 후에는 문화행사, 관광, 생활 등 주요 시정 소식을 시각화한 이미지로 제공해 행정 편의는 물론 시정 홍보까지 일석이조의 효과가 기대된다.

스마트 행정전화 알림서비스는 시청과 보건소, 사업소, 읍면동 행정복지센터 등 전 부서 행정전화에 적용된다. 스마트폰 이미지 표시 제공은 안드로이드 기반 스마트폰 기종에만 적용되며 아이폰 사용자는 애플 정책상 카카오톡 알림톡 서비스만 제공된다.

강수현 시장은 “시민들을 위한 다양한 시책과 축제, 행사 등이 이번 서비스를 통해 널리 홍보되고 시민들에게 다양한 혜택이 돌아갈 것으로 기대된다”며, “앞으로도 최신 정보통신 기술을 도입해 시민들의 편의 증진을 위한 다양한 시정 아이디어를 적극적으로 발굴, 반영하겠다”고 전했다.

출처 : 한국지방정부신문(http://www.localnewsroom.co.kr) 민원인의 호평을 받고 있다”며 “양주시청에서는 월 21만건 레터링 서비스를 지원하는 등 우수한 성과를 거뒀댜”고 말했다. 이어 “스마트레터링을 통해 지자체와 공공기관의 전통적인 소통방식을 혁신하고 민원인들에게 새로운 경험을 제공할 것”이라며 “지난해 조달청 디지털서비스몰에 상용SW로 도입한 상태이며, 향후 공공과 민간 전영역을 아우르는 서비스로 고도화할 계획”이라고 덧붙였다.`
    },
    {
      id:0,
      title:`부산 강서구 “행정전화 안심하고 받으세요”`,
      img:`<img src='/assets/news/부산강서구.jpg'} alt="뉴스이미지"/>`,
      date:`2023-01-16`,
      source:`https://www.nbntv.kr/news/articleView.html?idxno=296551`,
desc:`부산 강서구가 스마트폰 화면에 강서구 로고 화면이 표시돼 구청임을 알려주는 ‘강서구 행정전화 스마트 알림 서비스’ 구축에 들어간다.

스마트 알림 서비스는 구청 행정전화로 민원인에게 전화를 걸면 민원인의 스마트폰 화면에 구청 로고 화면 등이 자동으로 표시돼 구청에서 온 전화라는 것을 알려주는 주는 서비스이다.

통화가 종료된 뒤에는 강서구의 각종 설문조사, 축제 등 다양한 구정 소식 등을 안내하고 발신 부서 정보를 카카오톡 알림톡으로 전송하여 전화를 못 받았거나 추가 용건이 있을 때 민원인이 손쉽게 다시 전화할 수 있도록 한다.

이 서비스는 부산시 최초로 도입되며, 올해 6월까지 시스템 구축을 완료해 서비스를 시행할 예정이다.

그동안 행정전화로 전화를 걸 경우 민원인에게는 모르는 전화번호로 보여 보이스피싱, 스팸 전화로 오인하고 받지 않는 경우가 많았으나 서비스가 도입될 경우 민원인들이 안심하고 전화를 수신할 수 있어 신속한 행정 처리로 민원편의에 도움을 줄 것으로 기대된다.

휴대전화의 기종에 따라 안드로이드 기반 휴대폰만 가능하고 아이폰 사용자에게는 애플 정책상 카카오톡 알림톡 서비스만 제공된다.

강서구 관계자는 “앞으로도 구민 중심의 민원행정서비스를 추진을 위한 다양한 아이디어를 구상하고 최신 정보통신 활용 등으로 민원인의 편의를 더욱 증진해 나가겠다”고 말했다. 

정예진 기자 ekak2706@busaneconomy.com`
    },
  ]

  const truncate = (str, n) => {
    return str?.length > n ? str.substring(0, n - 1) + "..." : str;
  };

  const [viewReport, setViewReport] = useState(false)
  const [target, setTarget] = useState(
    () => JSON.parse(window.localStorage.getItem("target")) || 0
  );



  useEffect(() => {
    window.localStorage.setItem("target", JSON.stringify(target));
  }, [target]);




  const handleEvent = (e) =>{
    setViewReport(true)
    setTarget(e.currentTarget.id)


  }



  const location2 = useLocation();

  const [currentPage, setCurrentPage] = useState(
    () => JSON.parse(window.localStorage.getItem("currentPage")) || 1
  );
  const [postsPerPage, setPostsPerPage] = useState(
    () => JSON.parse(window.localStorage.getItem("postsPerPage")) || 4
  );

  const firstPostIndex = (currentPage - 1) * postsPerPage;
  const lastPostIndex = firstPostIndex + postsPerPage;
  console.log(currentPage);
  console.log(lastPostIndex);
  const currentPosts = news.slice(firstPostIndex, lastPostIndex);


  useEffect(() => {
    window.localStorage.setItem("currentPage", JSON.stringify(currentPage));
  }, [currentPage]);

  useEffect(() => {
    window.localStorage.setItem("postsPerPage", JSON.stringify(postsPerPage));
  }, [postsPerPage]);
  // console.log(currentPage)
  // console.log(firstPostIndex)
  // console.log(lastPostIndex)

   
   

  
  if(viewReport == true){

  }
  const newsList = currentPosts.map((news, index)=>{
    return (
        <li key = {news.id} className="news-info-list"
        onClick={handleEvent} id={index} newsid={news.id}>
          <Link to='/news/report/reportTemplate'>
            <div className='newsWrap'>
              <div className="thmb" dangerouslySetInnerHTML={{ __html: news.img}}>
              </div>
              <div className='descWrap'>
                  <div className="sub">
                    <strong className='newsTit'>{truncate(news.title , 35)}</strong>
                    <span className={newsStyle.date}>{news.date}</span>
                  </div>
                  <div className={newsStyle.desc}>{truncate(news.desc , 200)}
                  </div>
                </div>
              </div>
            </Link>
        </li>
    )
  })




  return(

      <div id="sb">
        <div className='inner1200'>
          <section className='section' 
          style={{display : location2.pathname === '/news/report/reportTemplate' ? "none" : "block"}}
          >
            <ul className="news-info">
              {newsList}
              
            </ul>
          </section>

           {/* {viewReport ? <ReportTemplate news={news} handleEvent={handleEvent} newsList={newsList} target={target} /> : ""}  */}
           <Pagination postsNum={news.length} postsPerPage={postsPerPage} setCurrentPage={setCurrentPage} currentPage={currentPage} news={news} 
           />

           <Routes>
            <Route path="/reportTemplate" element={<ReportTemplate news={news} handleEvent={handleEvent} newsList={newsList} target={target} currentPosts={currentPosts} />} />
          </Routes>  

        
        </div>
      </div>

  

  
  )
}


export default Report;