import React from 'react';
import Nav from '../components/Nav';
import style from './About.module.css'
import { Link } from 'react-router-dom';
import './History.css'

function History (props){



  return(
    <div id="sb" className='historysb' style={{paddingBottom:0}}>
      <div className='inner1200'>
        <h3 className="pageTit">연혁</h3>
        {/* <div className='itemBox'>
  
        <div className="text">
          <b>2020</b>
          <ul>
          
            <li>
              <span>2024.03</span>
              <div className="pBox">
                <p>이노비즈(기술혁신) 인증 획득</p>
              </div>
            </li>
          
            <li>
              <span>2024.02</span>
              <div className="pBox">
                <p>디자인특허 1건 획득</p>
              </div>
            </li>
          
            <li>
              <span>2024.01</span>
              <div className="pBox">
                <p>기술특허 획득 (레터링 서비스 제공 시스템[퍼스널레터링] 제10-2627512호)</p>
              </div>
            </li>
          
          </ul>
        </div>
      </div> */}
          <div className='historyWrap'>
            <div className='textBox'>
              <div className='sticky'>
                {/* <img src="/assets/@sample2.jpg" /> */}
                <p>다옴솔루션의 <br />발자취</p>
                <span>다옴솔루션이 걸어온 길을 소개합니다.</span>
              </div>
            </div>


            
            <div className='itemBox'>
            <div className="history-bar"><span></span></div>
            <div className="item firstItem">
            <div className="list">
                {/* <dl class="on">
                  <dt><span class="point"></span>2015.05</dt>
                  <dd>
                    <p>(주)다옴솔루션 설립 (사업자등록번호 : 550-88-00084) <br />
                    소프트웨어사업자 신고 <br />
                    벤처기업 인증 <br />
                    ISO 9001 인증 <br />
                    Solution Software 등록 <br />
                    기업부설연구소 등록 <br />
                    중소기업청 중소기업등록
                    </p>
                  </dd>
                </dl>

                <dl class="on">
                  <dt><span class="point"></span>2016.04</dt>
                  <dd>
                    <p>기업부설연구소 인가 승인</p>
                  </dd>
                </dl>

                <dl class="on">
                  <dt><span class="point"></span>2016.06</dt>
                  <dd>
                    <p>ISO 9001 인증</p>
                  </dd>
                </dl>

                <dl class="on">
                  <dt><span class="point"></span>2016.06</dt>
                  <dd>
                    <p>다옴솔루션 사업장 이전</p>
                  </dd>
                </dl>

                <dl class="on">
                  <dt><span class="point"></span>2016.11</dt>
                  <dd>
                    <p>SK브로드밴드 Business Partner 승인</p>
                  </dd>
                </dl>

                <dl class="on">
                  <dt><span class="point"></span>2017.03</dt>
                  <dd>
                    <p>삼성전자 Enterprise 파트너사 등록</p>
                  </dd>
                </dl>

                <dl class="on">
                  <dt><span class="point"></span>2019.08</dt>
                  <dd>
                    <p>정보통신공사업면허 획득</p>
                  </dd>
                </dl> */}

          <ul className='historyList'>
          <li>
							<div className="history-img">
                <div className='date'>
									<h3>2020~</h3>
								</div>
                <div className='historyImg' >
								  <img src="/assets/history1.jpg" alt=""/>
                </div>

                <div className='history-infoWrap'>
                <div className="history-info">
                  <div className='date'>
                    <span>2020.03
                    </span>
                  </div>
                  <div className='historyText'>
                    <p>직접생산확인증명 획득</p>
                  </div>
							  </div>

                <div className="history-info">
                  <div className='date'>
                    <span>2022.03
                    </span>
                  </div>
                  <div className='historyText'>
                    <p>직접생산확인증명 획득</p>
                  </div>
							  </div>

                <div className="history-info">
                  <div className='date'>
                    <span>2022.10
                    </span>
                  </div>
                  <div className='historyText'>
                    <p>스마트 레터링 v1.0 GS인증서 1등급 획득</p>
                  </div>
							  </div>

                <div className="history-info">
                  <div className='date'> 
                    <span>2022.11
                    </span>
                  </div>
                  <div className='historyText'>
                    <p>스마트 레터링 v1.0 조달쇼핑몰 등록</p>
                  </div>
							  </div>

                <div className="history-info">
                  <div className='date'>
                    <span>2022.12
                    </span>
                  </div>
                  <div className='historyText'>
                    <p>아우룸스위트 v1.0 GS인증서 1등급 획득</p>
                  </div>
							  </div>

                
                <div className="history-info">
                  <div className='date'>
                    <span>2023.01
                    </span>
                  </div>
                  <div className='historyText'>
                    <p>아우룸스위트 v1.0 조달쇼핑몰 등록</p>
                  </div>
							  </div>

                     
                <div className="history-info">
                  <div className='date'>
                    <span>2023.03
                    </span>
                  </div>
                  <div className='historyText'>
                    <p>기술특허 획득 (레터링 서비스 제공 시스템 및 방법 제10-2592511호)</p>
                  </div>
							  </div>

                     
                <div className="history-info">
                  <div className='date'> 
                    <span>2023.05
                    </span>
                  </div>
                  <div className='historyText'>
                    <p>기술특허 획득 (레터링 서비스 제공 시스템 제10-2606939호)</p>
                  </div>
							  </div>

                     
                <div className="history-info">
                  <div className='date'>
                    <span>2023.11
                    </span>
                  </div>
                  <div className='historyText'>
                    <p>디자인특허 1건 획득</p>
                  </div>
							  </div>

                <div className="history-info baseLine">
                  <div className='date'>
                    <span>2023.12
                    </span>
                  </div>
                  <div className='historyText'>
                    <p>아우룸스위트 v1.0 GS인증서 1등급 획득</p>
                    <p>디자인특허 10건 획득</p>
                  </div>
							  </div>

                <div className="history-info">
                  <div className='date'>
                    <span>2024.01
                    </span>
                  </div>
                  <div className='historyText'>
                    <p>기술특허 획득 (레터링 서비스 제공 시스템[퍼스널레터링] 제10-2627512호)</p>
                  </div>
							  </div>

                <div className="history-info">
                  <div className='date'>
                    <span>2024.02
                    </span>
                  </div>
                  <div className='historyText'>
                    <p>디자인특허 1건 획득</p>
                  </div>
							  </div>

                <div className="history-info history-info1">
                  <div className='date'>
                    <span>2024.03
                    </span>
                  </div>
                  <div className='historyText'>
                    <p>이노비즈(기술혁신) 인증 획득</p>
                  </div>
							  </div>
							</div>
              </div>
						</li>

						<li>
							<div className="history-img">
                <div>
									<h3>2015~2019</h3>
								</div>

                <div className='historyImg'>
								  <img src="/assets/partner.jpg" alt=""/>
                </div>

                <div className='history-infoWrap'>
                <div className="history-info baseLine">
                  <div className='date'>
                    <span>2015.05
                    </span>
                  </div>
                  <div className='historyText'>
                    <p>(주)다옴솔루션 설립 (사업자등록번호 : 550-88-00084)</p>
                    <p>소프트웨어사업자 신고</p>
                    <p>벤처기업 인증</p>
                    <p>ISO 9001 인증</p>
                    <p>Solution Software 등록</p>
                    <p>기업부설연구소 등록</p>
                    <p>중소기업청 중소기업등록</p>
                  </div>
							  </div>

                <div className="history-info">
                  <div className='date'>
                    <span>2016.04
                    </span>
                  </div>
                  <div className='historyText'>
                    <p>기업부설연구소 인가 승인</p>
                  </div>
							  </div>

                <div className="history-info baseLine">
                  <div className='date'>
                    <span>2016.06
                    </span>
                  </div>
                  <div className='historyText'>
                    <p>ISO 9001 인증</p>
                    <p>다옴솔루션 사업장 이전</p>
                  </div>
							  </div>

                <div className="history-info">
                  <div className='date'>
                    <span>2016.11
                    </span>
                  </div>
                  <div className='historyText'>
                    <p>SK브로드밴드 Business Partner 승인</p>
                  </div>
							  </div>

                <div className="history-info">
                  <div className='date'>
                    <span>2017.03
                    </span>
                  </div>
                  <div className='historyText'>
                    <p>삼성전자 Enterprise 파트너사 등록</p>
                  </div>
							  </div>

                
                <div className="history-info history-info1">
                  <div className='date'>
                    <span>2019.08
                    </span>
                  </div>
                  <div className='historyText'>
                    <p>정보통신공사업면허 획득</p>
                  </div>
							  </div>
							</div>
              </div>
						</li>

				
        
					</ul>
          
        </div>
       </div>
          </div>
        </div>

      </div>
    
    </div>
  
  )
}


export default History;