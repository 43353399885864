import React from 'react';
import Nav from '../components/Nav';
import style from './About.module.css'
import Submenu from './Submenu';
import Footer from '../components/Footer';
import { useRef , useState, useEffect } from "react";
import { Routes, Route, Link } from 'react-router-dom';

function solutionmaintenance (props){


  



  return(
    <div className='maintenanceWrap'>

      <div className='product'>
        <div className='inner1200'>
          <div className='titleWrap2'>
              <h1 className='solutionTit2'>솔루션 유지보수</h1>
              <p className='solutionText2 text_pc'>다옴솔루션은 Call center 솔루션, 상담AP 및 IPT 솔루션 유지관리 업무를 <span>전문적으로 수행</span>하며, <br />
              신속한 장애처리와 이력관리, 사전 장애 요소를 진단, 조치를 통해 <br /><span>최적의 시스템 유지관리</span>로 <span>고객만족에 최선의 노력</span>을 기울이고 있습니다.
              </p>
              <p className='solutionText2 text_mobile'>다옴솔루션은 Call center 솔루션, 상담AP 및 IPT 솔루션 유지관리 업무를 <span>전문적으로 수행</span>하며,
              신속한 장애처리와 이력관리, 사전 장애 요소를 진단, 조치를 통해 <span>최적의 시스템 유지관리</span>로 <span>고객만족에 최선의 노력</span>을 기울이고 있습니다.
              </p>
            </div>
        </div>
      </div>

      <div className='maintenance'>
        <div className='inner1200'>
          <div className='roundWrap'>
            <div className='roundWrap2'>
              <div className='roundWrap3'>
                <div className='round1'>IP-PBX</div>
                <div className='round1'>부가장비</div>
              </div>
              <div className='roundWrap4'>
                <div className='round1'>상담</div>
                <div className='round1'>CTI</div>
              </div>
            </div>
            <span className='arrow3'> &#62; </span>
            <div className='round'> 최적의 <br/>유지관리 업무수행
            {/* <div className='dashRound'></div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


export default solutionmaintenance;