import React from 'react';
import Nav from '../components/Nav';
import style from './About.module.css'
import Submenu from './Submenu';
import Footer from '../components/Footer';
import { useRef , useState, useEffect } from "react";
import { Routes, Route, Link } from 'react-router-dom';

function Solutionipt (props){


  const [activeTab, setActiveTab] = useState(0);
  const [index, setIndex] = useState(0);

  const func = [
    {
      id:0,
      title:'IPT 구성도',
      img:'/assets/solution/IPT.png',
      contentTitle:`행정기관의 인터넷 전화 인프라 구축 시 보안 및 암호화 기술을 적용한 C그룹 전화망 연동`,
      content:`IP 네트워크 기반의 인터넷전화 인프라를 구축함으로써 PSTN, TDM 교환기, 선로 등 기존의 음성 인프라에 투입되던 비용이 획기적으로 감소하였습니다. 음성 통화 전 구간의 암호화(TLS, sRTP) 및 기기 인증서 적용으로 업무의 기밀성을 확보 할 수 있게 되었습니다. 향후 모바일오피스, UC 등의 새로운 통신 서비스 적용 시에도 이번에 구축된 인프라를 기반 시스템으로 하여 최소, 최적의 비용으로 최신의 서비스를 제공할 수 있습니다.`
    },
    {
      id:1,
      title:'IPT 장점',
      img:'/assets/solution/IPT2.png',
      content:`<span>통합 매니지 먼트</span> 
      간단한 설치와 사용이 편리한 Call Processing, Applications, MGW 통합 관리 기능은 시스템 관리를 위한 비용 및 시간 절감이 가능 그룹차원의 통합 번호 체계 구축 지원
        
      <span>TCO 절감</span> 
      원격 제어를 통해 출장비용과 시간 절감 서버-애플리케이션 통합으로 서버 운영 비용 및 전력 소모 절감으로 투자보호 극대화
        
      <span>호환성</span> 
      효과적인 UC 및 Vertical Solutions 제공을 위해 SIP기반 Open Architecture로 3rd Party Applications 및 기존 기업 Applications 연동성 강화
        
      <span>고가용성</span> 
      시스템 NIC 다중화, Active-Active(Standby) 시스템 Redundancy , Survivable Gateway
        
      <span>유연성</span> 
      유연한 Dial Plan, Routing , CAC , Hot Desking 등으로 효율적인 ITP환경 구축 가능 SSW기술을 적용한 Pure IP-PBX와 동시에 대용량 GW 라인업을 갖춘 유일한 벤더로 통합망 구축에 유연성 제공 가능`
    },
  ]


  const funcList = func.map((func, index)=>{
    return(
          <li key={func.id}
          style={{border : activeTab == func.id ? "1px solid #ff4938" : ""}}
          >
            <a
          style={{color : activeTab == func.id ? "#ff4938" : ""}}
          data-active={activeTab == func.id ? "true" : "false"}
          onClick={(e)=>{
            setActiveTab(func.id);
            setIndex(func.id)

          }}
        >{func.title}</a></li>
    )
  })




  return(
    <div className='maintenanceWrap'>

      <div className='product'>
        <div className='inner1200'>
          <div className='titleWrap'>
              <h1 className='solutionTit'>Internet Protocol Telephony</h1>
              <p className='solutionText'>IP Telephony 기술을 이용하여 Multimedia Convergence를 중심으로 급변하는 신 환경에서 단순한 Infra.장비의 교체가 아닌 사용자 중심의 일반적인 업무 환경에서 기업 경쟁력 강화와 업무효율성 강화를 가져올 수 있는 글로벌 네트워크 공동체를 목표로 IP 기반 차세대 통신망을 구축 합니다. <br /><br />
              
              뿐만 아니라, IPT는 기존 기업 통신 환경에서 운영되었던 음성,데이터 통신을 하나의 네트워크로 통합 운영할 수 있는 솔루션입니다. 기업은 IP Telephony 를 도입함으로써 기존의 음성 및 데이터용으로 분리된 2개의 네트워크(망)를 유지하기 위해 지출되는 중복 비용을 절감할 수 있으며 향후 그룹웨어, UC 기능 등의 연동을 통한 Mobile Office의 기본 Infra를 구축 할 수 있습니다.
              </p>
            </div>
        </div>
      </div>

      <div className='ARSFuncWrap IPTFuncWrap'>
          <div className='inner1200'>
            <h4 className='adTit'>IPT의 <span>기능</span></h4>

            <div className='funcList'>
              <ul>
                {funcList}
              </ul>
            </div>

            <ul className='funcItemList'>
              {func.filter(item => index === item.id).map(item => (
                <li key={item.id}>
                <div className='IPTfuncItem'>
                  <div className='IPTfuncContent'>
                  <div className='IPTfuncImg'>
                    <img src={item.img} />
                  </div>
                    <h5 className='itemTit'>{item.contentTitle}</h5>
                    <p dangerouslySetInnerHTML={{ __html: item.content}}>
                
                    </p>
                  </div>

                </div>
              </li>
              ))}
          
            </ul>

          
          </div>
      </div>


    </div>
  )
}


export default Solutionipt;