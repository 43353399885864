import React from 'react';
import Nav from '../components/Nav';
import style from './About.module.css'
import Submenu from './Submenu';
import Footer from '../components/Footer';
import { useRef , useState, useEffect } from "react";
import { Routes, Route, Link } from 'react-router-dom';

function Solutionpersonal (props){


  



  return(
    <div id="sb2">

      <div className='product'>
        <div className='inner1200'>
          <div className='titleWrap'>
              <h1 className='solutionTit'>Personal Lettering</h1>
              <p className='solutionText'>퍼스널 레터링 솔루션은 개인간 스마트폰 끼리 레터링 서비스를 제공 할 수 있는 제품으로 자사 솔루션 앱을 설치, 회원 가입 후 요금제를 선택 하시면 사용 하실 수 있습니다.<br /><br />
              개인 폰 끼리 통화전에 프롤로그 (명함, 회사 홍보, 제품 이미지 등), 에필로그 (회사 홈페이지, 개인홈페이지, 개인 SNS, 개인명함 등)를 제작하여 선거 홍보, 차별화 영업 전략, 차별화된 기업 법인폰 홍보 서비스 제공이 가능합니다.
              </p>
            </div>
        </div>
      </div>

      <div className='pr_ep'>
          <div className='inner1200'>
          <h4 className='adTit adTit_pc'><span>다양한 컨텐츠</span> 노출이 가능한 나만의 <span>레터링</span></h4>
          <h4 className='adTit adTit_mobile'><span>다양한 컨텐츠</span> 노출이 가능한 <br />나만의 <span>레터링</span></h4>

          <p className='adSubTit'>통화 연결 전과 통화 연결 후 <br />
            다양한 컨텐츠 노출이 가능합니다.
          </p>
          <div className='SLImg'>
            <div className='pr'>
              <p><span>프롤로그</span>
              통화 연결 전까지 이미지 레터링 제공<br />
              회사 홍보<br/>
              선거-투표 홍보<br/>
              명함-제품 동영상 홍보<br />
              차별화 영업 전략
              </p>
              <img src="/assets/warrow1.png" className='arrow arrow1'/>
              <img src="/assets/solution/personal-pr.png" className='prImg'/>
            </div>
            <div className='ep'>
              <img src="/assets/warrow2.png" className='arrow arrow2'/>
              <img src="/assets/solution/personal-ep.png" className='epImg'/>
              <p><span>에필로그</span>
                통화 종료 시점 Contents 제공<br/>
                회사 홍보<br/>
                선거-투표 홍보<br/>
                명함-제품 동영상 홍보<br />
                차별화 영업 전략<br />
                자신의 SNS 홍보 <br />
                유튜브 홍보영상 
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className='SLFuncWrap'>
          <div className='inner1200'>
            <h4 className='adTit'>퍼스널레터링의 <span>기대효과</span></h4>
            <ul>
                <li>
                  <div className="box">회사 홍보</div>
                </li>
                <li>
                  <div className="box">선거-투표 홍보</div>
                  
                </li>
                <li>
                  <div className="box">명함-제품 동영상 홍보</div>
                </li>
                <li>
                  <div className="box">차별화 영업 전략</div>
                </li>
              </ul>

              <div className='table outIn'>
                <table>
                  <thead>
                    <tr>
                      <th>아웃바운드, 인바운드</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <p>회사 소개와 제품 홍보 가능<br />
                        선거 및 투표 홍보 가능<br/>
                        나만의 명함을 제작하여 홍보 가능<br />
                        제품 홍보 동영상 서비스 가능<br/>
                        제품 이미지나 상세 내용을 소개하여 차별화된 영업 가능
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
        </div>
            </div>

            
        </div>

        <div className='personalHome'>
          <div className='inner1200'>
            <div className='personalWrap'>
              <p>퍼스널레터링이 궁금하세요?</p>


              <div>
                <a href="https://www.personallettering.com/team">
                  <button className='goPersonal'>퍼스널레터링 홈페이지 바로가기</button>
                </a>
              </div>
            </div>
          </div>
        </div>
    </div>
  
  )
}


export default Solutionpersonal;