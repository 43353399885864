import React from 'react';
import './Footer.css';
import { Routes, Route, Link, useLocation } from 'react-router-dom';
// import style from './Footer.module.css'

function Footer (){

  const location3 = useLocation();

  return(
    <div className='footerback'
    style={{borderTop: location3.pathname === '/solution/solution1' || location3.pathname === '/solution/solution4' || location3.pathname === '/solution/solution5'? "1px solid rgb(203 203 203)" : ""}}>
      <div className='inner1570'>
        <div className='footerWrap'>
          <div className='footerImg'>
            <img src='/assets/ci.png'/>
          </div>
          <div className='footerText'>
            <p>
              <strong>㈜다옴솔루션 대표이사</strong> 윤지경<span className='width40'></span>
              <strong>주소</strong> 서울특별시 금천구 디지털로 10길 78 641,642,643호 <span className='width40'></span><br />
              <strong>TEL</strong> 02-6230-6700 <span className='width40'></span>
              <strong>FAX</strong> 02-6230-6701  <span className='width40'></span>
              <strong>E-MAIL</strong> jk.yoon@daomsolution.com <span className='width40'></span><br />
              COPYRIGHT@DAOMSOLUTION.CO.,LTD ALL RIGHT RESERVED
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}


export default Footer;
