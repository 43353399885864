import React, { useState } from 'react';
import style from './Company.module.css';
import { faBuilding } from "@fortawesome/free-solid-svg-icons";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom';

function Company (){


  return(
    <div className='companyback'>
    <div className="inner1570">
      <div className='FlexWrap'>
        {/* <div className='left'>
          <img src='/assets/@sample2.jpg' /> 
        </div> */}

        <div className='titleBox titleBox2'>
          <span className='why'>Who we are</span>
          <h2 className='pc'>다옴솔루션을 <br/> 소개합니다!</h2>
          <h2 className='mobile'>다옴솔루션을 소개합니다!</h2>
          <h5>다옴솔루션은, 최고의 기술력으로 <br />
          누구보다 빠른 대처와 남들보다 한발 앞서는 선도 기업으로<br />
          발전하기 위해 항상 노력하고 있습니다</h5>
          {/* <div className='intro-list'>
            <ul>
              <li className='btn6'><a>인삿말
              </a></li>
              <li className='btn6'><a>회사연혁</a></li>
              <li className='btn6'><a>CI</a></li>
              
            </ul>
          </div> */}
          <div className='buttonWrap'>
            <button className='overView' onClick={()=>{
              window.location.href='/about/greeting'
            }}><a>Overview<FontAwesomeIcon icon={faArrowRightLong}  /></a>

            </button>
          </div>
        </div>

        <div className={style.contentBox}>
          <ul>
            <li onClick={()=>{
              window.location.href='/about/greeting'
            }}>
                 <img src="/assets/icon/icon1.png" alt="인삿말" /> 
                <div className={style.relative}>
									<h6>인삿말</h6>
                  <span>끊임없는 변화와 혁신으로 IPT/IPCC 분야에서
'최고로 인정받는 선도기업' 다옴솔루션입니다.</span>
								</div>
            </li>
            <li onClick={()=>{
              window.location.href='/about/history'
            }}>
               <img src="/assets/icon/icon2.png" alt="연혁" /> 
                <div className={style.relative}>
									<h6>연혁</h6>
                  <span>2015년 창립 이후부터 현재까지
다옴솔루션의 걸어온 길을 소개합니다.</span>
								</div>
            </li>
            <li onClick={()=>{
              window.location.href='/about/partner'
            }}>
                 <img src="/assets/icon/icon3.png" alt="파트너사" /> 
                <div className={style.relative}>
									<h6>파트너사</h6>
                  <span>다양한 파트너사가 다옴솔루션과 함께합니다.</span>
								</div>
            </li>
            <li onClick={()=>{
              window.location.href='/about/vision'
            }}>
               <img src="/assets/icon/icon4.png" alt="비전/CI" /> 
                <div className={style.relative}>
									<h6>비전 / CI</h6>
                  <span>다옴솔루션은 최고의 기술력으로 남들보다 한발 앞서는 선도 기업으로 발전하기 위해 항상 노력하고 있습니다</span>
								</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  )
}


export default Company;