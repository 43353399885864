import React from 'react';
import Nav from '../components/Nav';
import style from './About.module.css'
import Submenu from './Submenu';
import Footer from '../components/Footer';
import { useRef , useState, useEffect } from "react";
import { Routes, Route, Link } from 'react-router-dom';
import './Recruit.css'

function Recruit1 (props){




  return(
    <div id="sb">
      <div className='recruit'>
        <div className='inner1200'>
          <div className='titleWrap2'>
              <h1 className='solutionTit2'>다옴솔루션이 바라는 인재상</h1>
              {/* <p className='solutionText2'> 다옴솔루션의 핵심 가치를 실천할 수 있는 인재
              </p> */}
          </div>
          {/* <h4 className='adTit'>다옴솔루션이 바라는 인재상</h4>

          <p className='adSubTit'>
            다옴솔루션의 핵심 가치를 실천할 수 있는 인재
          </p> */}

          
          <ul className="recruit-list">
					<li>
						<div className="dl">
							<dl>
								<dt>Professionalism</dt>
								<dd>
                  프로정신과 담당업무에 대해 <br />
                  <strong>최고의 전문지식</strong>을 겸비해 <br />
                  고객에 최선의 서비스를 제공하는 인재
                </dd>
							</dl>
						</div>
						<div className="image">
							<figure>
								<img src="/assets/recruit/recruit01.jpg" alt="" />
							</figure>
						</div>
					</li>
					<li>
						<div className="dl">
							<dl>
								<dt>Sincerity</dt>
								<dd>
                  정직함과 성실함을 바탕으로 <br />
                  책임있는 행동으로 <strong>타인에게 믿음</strong>을 주는 인재
                </dd>
							</dl>
						</div>
						<div className="image">
							<figure>
                <img src="/assets/recruit/recruit02.jpg" alt="" />
							</figure>
						</div>
					</li>
					<li>
						<div className="dl">
							<dl>
								<dt>Communication</dt>
								<dd>
                  개개인의 능력도 중요하지만 <br/>
                  팀원 간의 <strong>협력과 소통을 중요시 </strong>여기는 인재
                </dd>
							</dl>
						</div>
						<div className="image">
							<figure>
                <img src="/assets/recruit/recruit03.jpg" alt="" />
							</figure>
						</div>
					</li>
					<li>
						<div className="dl">
							<dl>
								<dt>Responsibility</dt>
								<dd>
                  주어진 과업에 끝까지 최선을 다하며 <br />
                  자신의 행동에 대한 <strong>책임을 받아들이는 </strong>인재 
                </dd>
							</dl>
						</div>
						<div className="image">
							<figure>
                <img src="/assets/recruit/recruit04.jpg" alt="" />
							</figure>
						</div>
					</li>
				</ul>
    
  </div>
  </div>
  </div>



  
  )
}


export default Recruit1;