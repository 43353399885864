import React from 'react';
import Nav from '../components/Nav';
import style from './About.module.css'
import { Link } from 'react-router-dom';
import './Vision.css';
import visionImg1 from '../img/vision01.png';
import visionImg2 from '../img/vision02.png';
import visionImg3 from '../img/vision03.png';
import logo from '../img/ci.png';
import whiteLogo from '../img/w-ci.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLightbulb } from "@fortawesome/free-regular-svg-icons";
import './Partner.css'
import partnerImg from '../img/partner/partner_01.jpg';
import partnerImg2 from "../img/partner/partner_02.jpg";
import partnerImg3 from "../img/partner/partner_03.jpg"
import partnerImg4 from "../img/partner/partner_04.jpg"
import partnerImg5 from "../img/partner/partner_05.jpg"
import partnerImg6 from "../img/partner/partner_06.jpg"
import partnerImg7 from "../img/partner/partner_07.jpg"
import partnerImg8 from "../img/partner/partner_08.jpg"
import partnerImg9 from "../img/partner/partner_09.jpg"
import partnerImg10 from "../img/partner/partner_10.jpg"
import partnerImg11 from "../img/partner/partner_10.png"
import partnerImg12 from "../img/partner/partner_11.jpg"
import partnerImg13 from "../img/partner/partner_12.jpg"
import partnerImg14 from "../img/partner/partner_13.jpg"
import partnerImg15 from "../img/partner/partner_14.jpg"
import partnerImg16 from "../img/partner/partner_15.jpg"
import partnerImg17 from "../img/partner/partner_16.jpg"
import partnerImg18 from "../img/partner/partner_17.png"
import partnerImg19 from "../img/partner/partner_18.jpg"
import partnerImg20 from "../img/partner/partner_19.jpg"
import partnerImg21 from "../img/partner/partner_20.jpg"
import partnerImg22 from "../img/partner/partner_21.jpg"
import partnerImg23 from "../img/partner/partner_22.jpg"
import partnerImg24 from "../img/partner/partner_23.jpg"
import partnerImg25 from "../img/partner/partner_24.jpg"
import partnerImg26 from "../img/partner/partner_25.jpg"
import partnerImg27 from "../img/partner/partner_26.jpg"
import partnerImg28 from "../img/partner/partner_27.jpg"
import partnerImg29 from "../img/partner/partner_28.jpg"
import partnerImg30 from "../img/partner/partner_29.jpg"
import partnerImg31 from "../img/partner/partner_30.png"
import partnerImg32 from "../img/partner/partner_31.jpg"

import partnerImg33 from "../img/partner/partner_2_01.jpg"
import partnerImg34 from "../img/partner/partner_2_02.jpg"
import partnerImg35 from "../img/partner/partner_2_04.jpg"
import partnerImg36 from "../img/partner/partner_2_05.jpg"
import partnerImg37 from "../img/partner/partner_2_06.jpg"
import partnerImg38 from "../img/partner/partner_2_07.jpg"
import partnerImg39 from "../img/partner/partner_2_08.jpg"
import partnerImg40 from "../img/partner/partner_2_09.jpg"
import partnerImg41 from "../img/partner/partner_2_10.jpg"

function Partner (){



  return(
    <div id="sb">
      <div className='inner1200'>
        <h3 className="pageTit">고객사</h3>
        <div className='subTit subTit1'>
          <span>다양한 지자체와 협력하여 시민들의 삶을 향상시키기 위해 <br /> 다옴솔루션은 최선을 다합니다</span>
          <span></span>
          <div className='partnerWrap'>
            <div className='government'>
              <ul>
                <li id="government-li"><img src="/assets/logo/bsnamgu.png" alt="파트너사1" /></li>
                <li id="government-li"><img src="/assets/logo/gimpo.png" alt="파트너사1" /></li>
                <li id="government-li"><img src="/assets/logo/jejudo.png" alt="파트너사1" /></li>
                <li id="government-li"><img src="/assets/logo/pyeongtaek.png" alt="파트너사1" /></li>
                <li id="government-li"><img src="/assets/logo/yangju.png" alt="파트너사1" /></li>
                <li id="government-li"><img src="/assets/logo/ydp.png" alt="파트너사1" /></li>
                <li id="government-li"><img src="/assets/logo/yongsan.png" alt="파트너사1" /></li>
                
                </ul>
              </div>
            </div>
        </div>

        <div className='subTit subTit1 subTit2'>
          <span>다양한 고객사가 다옴솔루션과 함께합니다</span>
          <span></span>
        </div>
        <div className='partnerWrap'>
          <div className='partner1'>
            <ul>
              <li><img src={partnerImg} alt="파트너사1" /></li>
              <li><img src={partnerImg2} alt="파트너사2" /></li>
              <li><img src={partnerImg3} alt="파트너사3" /></li>
              <li><img src={partnerImg4} alt="파트너사4" /></li>
              <li><img src={partnerImg5} alt="파트너사5" /></li>
              <li><img src={partnerImg6} alt="파트너사6" /></li>
              <li><img src={partnerImg7} alt="파트너사7" /></li>
              <li><img src={partnerImg8} alt="파트너사8" /></li>
              <li><img src={partnerImg9} alt="파트너사9" /></li>
              <li><img src={partnerImg10} alt="파트너사10" /></li>
              <li><img src={partnerImg11} alt="파트너사10" /></li>
              <li><img src={partnerImg12} alt="파트너사11" /></li>
              <li><img src={partnerImg13} alt="파트너사12" /></li>
              <li><img src={partnerImg14} alt="파트너사13" /></li>
              <li><img src={partnerImg15} alt="파트너사14" /></li>
              <li><img src={partnerImg16} alt="파트너사15" /></li>
              <li><img src={partnerImg17} alt="파트너사16" /></li>
              <li><img src={partnerImg18} alt="파트너사17" /></li>
              <li><img src={partnerImg19} alt="파트너사18" /></li>
              <li><img src={partnerImg20} alt="파트너사19" /></li>
              <li><img src={partnerImg21} alt="파트너사20" /></li>
              <li><img src={partnerImg22} alt="파트너사21" /></li>
              <li><img src={partnerImg23} alt="파트너사22" /></li>
              <li><img src={partnerImg24} alt="파트너사23" /></li>
              <li><img src={partnerImg25} alt="파트너사24" /></li>
              <li><img src={partnerImg26} alt="파트너사25" /></li>
              <li><img src={partnerImg27} alt="파트너사26" /></li>
              <li><img src={partnerImg28} alt="파트너사27" /></li>
              <li><img src={partnerImg29} alt="파트너사28" /></li>
              <li><img src={partnerImg30} alt="파트너사29" /></li>
              <li><img src={partnerImg31} alt="파트너사30" /></li>
              <li><img src={partnerImg32} alt="파트너사31" /></li>
              </ul>
            </div>

            <div className='subTit subTit1 subTit2'>
              <span>다양한 협력사가 다옴솔루션과 함께합니다</span>
              <span></span>
           </div>
            <div className='partner2'>
              <ul>
                <li><img src={partnerImg33} alt="협력사1" /></li>
                <li><img src={partnerImg34} alt="협력사2" /></li>
                <li><img src={partnerImg35} alt="협력사4" /></li>
                <li><img src={partnerImg36} alt="협력사5" /></li>
                <li><img src={partnerImg37} alt="협력사6" /></li>
                <li><img src={partnerImg38} alt="협력사7" /></li>
                <li><img src={partnerImg39} alt="협력사8" /></li>
                <li><img src={partnerImg40} alt="협력사9" /></li>
                <li><img src={partnerImg41} alt="협력사10" /></li>
              </ul>
            </div>
        </div>
   
      </div>
      
    </div>
    )
}

export default Partner;