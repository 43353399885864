import React from 'react';
import Nav from '../components/Nav';
import style from './About.module.css'
import Submenu from './Submenu';
import Footer from '../components/Footer';
import { useRef , useState, useEffect } from "react";
import { Routes, Route, Link } from 'react-router-dom';

function Solutionipcc (props){


  const [activeTab, setActiveTab] = useState(0);
  const [index, setIndex] = useState(0);

  const func = [
    {
      id:0,
      title:'CTI',
      img:'/assets/solution/CTI.png',
      content:`IP Contact Center는 VoIP(IPT) 기술을 CTI와 접목시켜 공간적 제약을 해결 하여, 재택상담 및 타 지역 센터 운영을 가능 하게 하였습니다. E-Mail, Chat, 영상 상담과 같은 다양한 Channel를 통합 분배 함으로서 획기적인 생산성 향상, 고객만족도 향상을 지원합니다. IP Contact Center의 부가장비는 IPT방식으로 연동함으로 기존 TDM 방식보다 장애 요소가 적으며, 채널 증설 시 License 증설만으로 가능하여 비용을 절약할 수 있습니다.`
    },
    {
      id:1,
      title:'전광판 시스템',
      img:'/assets/solution/전광판.png',
      content:`전광판 시스템이란 콜 센터 상담원 및 관리자에게 필요한 콜 센터 운영 상황 정보를 영상 매체를 통하여 실시간으로 정보를 제공하는 통계모니터링시스템으로 내부 상담 상황을 표시 해 주고, 상담 센터의 상담 분위기를 고취하는데 효과적인 시스템 입니다. 또한 시스템운영자 및 콜 센터 관리자를 위한 모바일 전광판App을 제공하여 상담센터의 사항을 콜센터가 아닌 장소에서도 실시간 모니터링 할 수 있습니다.`
    },
    {
      id:2,
      title:'상담 어플리케이션',
      img:'/assets/solution/application.png',
      content:`CTI Middleware와의 연계를 통해 고객 센터의 주요한 기능들을 상담원이 편리하게 이용할 수 있으며 IVR/FAX, ERMS, SMS등과의 연동을 통해 고객과의 모든 접점을 고객센터 안에서 일관되게 관리할 수 있도록 개발된 솔루션입니다.`
    },
    {
      id:3,
      title:'녹음안내',
      img:'/assets/solution/녹음.png',
      content:`녹음 안내 시스템은 전화 수신 시 해당 담당자와 연결 전에 녹음이 시작된다는 안내 음성을 내보낼 수 있습니다. 또한 선택적으로 고객이 언어폭력 및 음담패설을 할 때, 전화기에 버튼을 누르면, 녹음 중이라는 안내 음성을 내보내어 고객으로 하여금 해당 행위에 대한 처벌의 증거로써 음성녹음이 되는 것을 일깨워 주어 사전 언어폭력의 행위를 제재 할 수 있습니다.`
    },
    {
      id:4,
      title:'포기호 관리',
      img:'/assets/solution/포기호.png',
      content:`포기호 관리 시스템은 포기호에 대한 호 정보를 실시간 수집하여 추가 기능으로 선택한 상담원에게 자동으로 포기호를 분해하여 고객의 반복적인 통화접속을 방지하여 회선 운영 및 상담원 운영에 효율성을 극대화 할 수 있는 솔루션입니다.`
    },
    {
      id:5,
      title:'언어폭력방지',
      img:'/assets/solution/언어폭력.png',
      content:`POVVS(포브스)는 상담시스템에 Embed되어 고객의 언어폭력 사실에 대한 고지, 전문 상담원 연결 및 Black List 관리를 One-Stop 으로 구현해주는 시스템입니다.`
    },
    {
      id:6,
      title:'STT & TA',
      img:'/assets/solution/STT.png',
      content:`녹취파일을 화자분리하여 STT시스템의 인식율을 높이고, 상담업무 품질향상을 위한 효율적인 모니터링 지원 및 향후 빅데이터 활용을 통한 부가가치 창출의 기반을 구축합니다.`
    },
    {
      id:7,
      title:'챗봇 시스템',
      img:'/assets/solution/챗봇.png',
      content:`문자 기반의 고객상담으로 컨텍센터의 상담 운영 효율을 높이고 마케팅 채널 활용으로 기업 이익 창출에 도움이 됩니다.`
    },
    {
      id:8,
      title:'PIN-PAD 시스템',
      img:'/assets/solution/PINPAD.png',
      content:`PIN-PAD 시스템은 삼성전자 IP교환기(SCME)와의 정합성이 매우 뛰어난 제품으로써 안정된 서비스를 제공하여 전사 사용자들이 편리한 상담업무 및 고객이 안전하고 편하게 사용할 수 있도록 시스템이 주민번호를 수집하게 됩니다.`
    },
  ]


  const funcList = func.map((func, index)=>{
    return(
          <li key={func.id}
          style={{border : activeTab == func.id ? "1px solid #ff4938" : ""}}
          >
            <a
          style={{color : activeTab == func.id ? "#ff4938" : ""}}
          data-active={activeTab == func.id ? "true" : "false"}
          onClick={(e)=>{
            setActiveTab(func.id);
            setIndex(func.id)

          }}
        >{func.title}</a></li>
    )
  })




  return(
    <div className='maintenanceWrap'>

      <div className='product'>
        <div className='inner1200'>
          <div className='titleWrap'>
              <h1 className='solutionTit'>Internet Protocol Contact Center</h1>
              <p className='solutionText'>IPCC 구축 시 IP 시스템(IP-IVR, IP 녹취 서버 등)은 TDM 방식일 때보다 하드웨어의 비용을 줄일 수 있고, 증설이 간편합니다.  <br /><br />
              
              또한 고객 만족도 증대 및 채널의 다양화, 대형화 전문화로 변화하는 Contact Center의 기능적 요구사항 및 운영 효율성 향상을 충족하기 위한 다양한 솔루션들이 지속적으로 연구 개발되어 지고 있습니다.
              </p>
            </div>
        </div>
      </div>

      <div className='ARSFuncWrap IPTFuncWrap'>
          <div className='inner1200'>
            <h4 className='adTit'>IPCC의 <span>기능</span></h4>

            <div className='funcList IPCCfuncList'>
              <ul>
                {funcList}
              </ul>
            </div>

            <ul className='funcItemList'>
              {func.filter(item => index === item.id).map(item => (
                <li key={item.id}>
                <div className='IPTfuncItem'>
                  <div className='IPTfuncContent'>
                  <div className='IPTfuncImg'>
                    <img src={item.img} />
                  </div>
                    <h5 className='itemTit'>{item.contentTitle}</h5>
                    <p dangerouslySetInnerHTML={{ __html: item.content}}>
                
                    </p>
                  </div>

                </div>
              </li>
              ))}
          
            </ul>

          
          </div>
      </div>

    </div>
  )
}


export default Solutionipcc;