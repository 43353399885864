import React from 'react';
import Nav from '../components/Nav';
import style from './About.module.css'
import Submenu from './Submenu';
import Footer from '../components/Footer';
import { useRef , useState, useEffect } from "react";
import './Recruit.css';
import { Routes, Route, Link, useLocation } from 'react-router-dom';
import Support1 from './Support1';
import Support2 from './Support2';
import newsStyle from './News.module.css';
import './News.css';
import NoticeTemplate from './NoticeTemplate';

function Notice (props){

  let news = [
    {
      id:0,
      title:`[공지사항]스마트레터링 특허 침해 방지에 대한 내용`,
      img:`/assets/sampleImg.png`,
      date:`2024-07-16`,
      desc:`
1. 스마트레터링 v1.0(Aurum-Lettering v1.0) 제품은 콜게이트 컨텐츠 서버를 사용하지 않고 별도의 컨텐츠 서버를 구축하여 독자적인 기술 특허와 디자인 특허를 취득한 제품으로 프롤로그/에필로그에 카드뉴스, 유튜브 영상, SNS 모음, 수신거부 등 다양한 컨텐츠를 독점으로 제공합니다. 

2. 스마트레터링 v1.0(Aurum-Lettering v1.0) 제품은 다옴솔루션 만의 독창적인 디자인 특허를 등록하여 고객이 원하는 다양한 화면 디자인이 표시된 디스플레이 컨텐츠를 프롤로그/에필로그에 제공 하고 있습니다. 

- 이 서비스/제품은 저작권 및 상표권에 보호받고 있습니다.
- 무단 복제나 도용은 법적인 조치를 수반할 수 있습니다.
- 사용 권한이 없는 경우 사용을 중단해 주십시오.
- 만약 무시하고 도용 및 권리 침해 시는 민, 형사 소송 및 손해 배상 청구됨을 알려드립니다.`
    },
    {
      id:1,
      title:`[공지사항]다옴솔루션 홈페이지가 리뉴얼 되었습니다.`,
      img:`/assets/renewal.jpg`,
      date:`2024-07-16`,
desc:`다옴솔루션 홈페이지가 새롭게 리뉴얼 되었습니다.
앞으로도 끊임없이 도전하고 성장할 ㈜다옴솔루션에 많은 관심과 성원을 부탁 드립니다.`
    }
  ]

  const truncate = (str, n) => {
    return str?.length > n ? str.substring(0, n - 1) + "..." : str;
  };

  const [viewReport, setViewReport] = useState(false)
  const [target, setTarget] = useState(
    () => JSON.parse(window.localStorage.getItem("target")) || 0
  );

  useEffect(() => {
    window.localStorage.setItem("target", JSON.stringify(target));
  }, [target]);

  const handleEvent = (e) =>{
    setViewReport(true)
    setTarget(e.currentTarget.id)
  }


  
  if(viewReport == true){

  }

  
  const newsList = news.map((news, index)=>{
    return (
        <li key = {news.id} className="news-info-list"
        onClick={handleEvent} id={index}>
          <Link to='/news/notice/noticeTemplate'>
            <div className='newsWrap'>
              <div className="thmb">
                <img src={news.img} alt="뉴스1" />
              </div>
              <div className='descWrap'>
                  <div className="sub">
                    <strong className='newsTit'>{truncate(news.title , 35)}</strong>
                    <span className={newsStyle.date2} >{news.date}</span>
                  </div>
                  {/* <div className={newsStyle.desc}>{truncate(news.desc , 200)}
                  </div> */}
                </div>
              </div>
            </Link>
        </li>
    )
  })

  
  const location3 = useLocation();

  return(

    <div id="sb">
      <div className='inner1200'>
        {/* <div className='titleWrap2'>
            <h1 className='solutionTit2'>채용공고</h1>
        </div> */}
        {/* <div className='noRecruit'>
          <img src="/assets/info.png" alt=""/>
          <p>공지사항이 없습니다.</p>
        </div> */}

        {/* <div className='titleWrap2'>
              <h4 class="adTit">다옴솔루션의 공지사항 입니다. </h4>
        </div>  */}

        {/* <div className='noticeWrap'>
        <table>
                 <thead>
                  <tr>
                    <th style={{width:'15%'}}>번호</th>
                    <th style={{width:'65%'}}>제목</th>
                    <th>작성일</th>
                  </tr>
                </thead> 
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>[공지사항]특허 침해 방지에 대한 내용</td>
                    <td>2024-05-16</td>
                  </tr>
                </tbody>
              </table>
        </div> */}

          <section className='section' 
          style={{display : location3.pathname === '/news/notice/noticeTemplate' ? "none" : "block"}}
          >
            <ul className="news-info">
              {newsList}
              
            </ul>
          </section>
     
          <Routes>
            <Route path="/noticeTemplate" element={<NoticeTemplate news={news} handleEvent={handleEvent} newsList={newsList} target={target} />} />
          </Routes>  
    </div>
  </div>

  
  )
}


export default Notice;