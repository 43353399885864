import React from 'react';
import Nav from '../components/Nav';
import style from './About.module.css'
import Submenu from './Submenu';
import Footer from '../components/Footer';
import { useRef , useState, useEffect } from "react";
import './Recruit.css';
import { Routes, Route, Link } from 'react-router-dom';
import Support1 from './Support1';
import Support2 from './Support2';
import newsStyle from './News.module.css';
import './News.css';
import reportStyle from './ReportTemplate.module.css';
import { Outlet } from 'react-router-dom';

function ReportTemplate (props){

  //console.log('video' in props.news[props.target])

  // console.log(props.news[props.target].title)
  return(

           <section>
              <div className={reportStyle.view_info}>
                <div className={reportStyle.sub}>
                    <h4 id={reportStyle.news_subject}>{props.currentPosts[props.target].title}</h4>
                </div>
                <div id="news-date" className={reportStyle.date}>{props.currentPosts[props.target].date}</div>
              </div>
          
          <div className='relative'>
            <div className={reportStyle.contentWrap}>
              <div className={reportStyle.imgWrap}
              style={{display: 'video' in props.currentPosts[props.target]? 'none': 'block'}}>
                <div dangerouslySetInnerHTML={{ __html: props.currentPosts[props.target].img}}>
                </div>
              </div>  

              <div className={reportStyle.videoWrap} 
              style={{display: 'video' in props.currentPosts[props.target]? 'block': 'none'}} dangerouslySetInnerHTML={{ __html: props.currentPosts[props.target].video}}>
              </div> 


              <div className={reportStyle.content} style={{paddingTop: 'video' in props.currentPosts[props.target]? '377px': ''}}>
                <p className={reportStyle.desc}>{props.currentPosts[props.target].desc}
                </p>
              
                <p className={reportStyle.source} style={{position : 'relative', 'z-index':'2'}}>
                  <a href={props.currentPosts[props.target]?.source}>※ 출처 : {props.currentPosts[props.target].source}
                  </a>
                </p>
              </div>
              <div className={reportStyle.button} style={{position : 'relative', 'z-index':'2'}}>
                <a href="/news/report">
                  <button className={reportStyle.news_list}>목록으로</button>
                </a>
              </div>
            </div>
          </div>

          </section>

  
  )
}


export default ReportTemplate;