import React from 'react';
import './Nav.css'
import { useRef , useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import Company from './Company';
import { Routes, Route } from 'react-router-dom';
import Intro  from '../pages/About';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from '../img/ci.png';
import whiteLogo from '../img/w-ci.png';
import MobileNav from './MobileNav';

function Nav (props){
  
  const location = useLocation();
  // const [scrollY, setScrollY] = useState(false);

  // const scrollFixed = () => {
  //   if (window.scrollY > 20) {
  //     setScrollY(true);
  //   } else {
  //     setScrollY(false);
  //   }
  // };

  // useEffect(() => {
  //   console.log(window.scrollY)
  //   const scrollListener = () => {
  //     window.addEventListener('scroll', scrollFixed);
  //   };
  //   scrollListener();
    
  //   return () => {
  //     window.removeEventListener('scroll', scrollFixed);
  //   };
  // }, );

  const html = document.querySelector('html');

  const MoveToTop = () => {
    // top:0 >> 맨위로  behavior:smooth >> 부드럽게 이동할수 있게 설정하는 속성
    html.scrollTo(0, 0);
    props.outerDivRef.current.scrollTo({
      top: 0 
      ,left:0
      ,behavior:"smooth"
    });
    props.setScrollIndex(0);
    };

    let [openClass, setOpenClass] = useState(false);
    const [mobileNav, setMobileNav] = useState(false)

    const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  
    const resizeListener = () => {
      setInnerWidth(window.innerWidth);
    };


  
    useEffect(() => {
      window.addEventListener("resize", resizeListener);
  
      return () => {
        window.removeEventListener("resize", resizeListener);
      };
    }, []); // 빈 배열을 전달하여 컴포넌트가 마운트될 때 한 번만 실행되도록 설정

   
  //console.log(props.scroll)

  const [scroll, setScroll] = useState(false);
  
  
  const handleScroll = () => {
   
    
      if(window.scrollY >= 200){
        setScroll(true);
       
      }else{
        setScroll(false);
      }
  
    };
    
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll); //clean up
        };
      }, [scroll]);
  

     
      // console.log(scroll == false && props.scrollIndex == 0 || props.scrollIndex == 2)

      


      const [currentPath, setCurrentPath] = useState('') 

      const onClickHandler = (e) => {
        // console.log(e.target.getAttribute('href'))
        setCurrentPath(e.target.getAttribute('href'))
      }
    
      useEffect(() => {
        if(currentPath === location.pathname) window.location.reload();
      }, [location]);
   

      
  return(
    <div
    className='nav fix'
    style={{ borderBottom:props.scrollIndex == 0  || props.scrollIndex == 2 ? "1px solid rgb(255,255,255,0.3)" : "1px solid rgb(0, 0,0,.1)" , background : scroll == false && props.scrollIndex == 0 || props.scrollIndex == 2 ? "transparent" : "#fff" , 
    //background: scroll == false ? '':'#fff' 
    // position: location.pathname === '/main' ? 'fixed' : 'static'
  }}
    >
      <div className='inner1700'>
        <div className='flexWrap'>
          <Link to='/main'>
            <img 
            onClick={MoveToTop}
            src=
            {scroll == false && props.scrollIndex == 0 || props.scrollIndex == 2 ? `/assets/w-ci.png` : "/assets/ci.png"}
            />
            
          </Link>
          <ul className='pcNav'>
            <li>
              {/* <a style={{color:props.scrollIndex == 0 || props.scrollIndex == 2 ? "#fff" : "#000"}}> */}
                <Link to="/about/greeting" style={{color:props.scrollIndex == 0 || props.scrollIndex == 2 ? "#fff" : "#000"}} onClick={onClickHandler}>회사소개</Link>
              {/* </a> */}
              </li>
            <li><Link to="/solution/solutionsmart" style={{color:props.scrollIndex == 0 || props.scrollIndex == 2 ? "#fff" : "#000"}} onClick={onClickHandler}>제품 및 솔루션</Link></li>
            <li><Link to="/recruit/recruit1" style={{color:props.scrollIndex == 0 || props.scrollIndex == 2 ? "#fff" : "#000"}} onClick={onClickHandler}>인재채용</Link></li>
            <li><Link to="/support/support1" style={{color:props.scrollIndex == 0 || props.scrollIndex == 2 ? "#fff" : "#000"}} onClick={onClickHandler}>고객지원</Link></li>
            <li><Link to="/news/report" style={{color:props.scrollIndex == 0 || props.scrollIndex == 2 ? "#fff" : "#000"}} onClick={onClickHandler}>뉴스</Link></li>
          </ul>

          <div className='navIconWrap'>
            <div id="nav-icon3" onClick={()=>{
              setOpenClass(!openClass)
              setMobileNav(!mobileNav)
            }}
            className={openClass === true ? 'open' : ''}>
              <div>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>


        </div>
      </div>

      {
      	mobileNav == true && innerWidth < 1345 ? <MobileNav innerWidth = {props.innerWidth} /> : null 
        }
    </div>
  )
}


export default Nav;