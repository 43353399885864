import React from 'react';
import Nav from '../components/Nav';
import style from './About.module.css'
import Submenu from './Submenu';
import Footer from '../components/Footer';
import { useRef , useState, useEffect } from "react";
import { Routes, Route, Link } from 'react-router-dom';
import './Modal.css'

function Modal (props){

  console.log(props.setModal)

  return(
      <div className="modalWrap">
        <div className="modal">
          <div className="member">

          <div className='imgWrap' dangerouslySetInnerHTML={{__html: props.modalImg}}></div>
          
          <button className="close" onClick={()=>{
            props.setModal(false)
          }}>닫기</button>
          
          </div>
        </div>
      </div>
  )
}


export default Modal;