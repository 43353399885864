import React from 'react';
import Nav from '../components/Nav';
import style from './About.module.css'
import Submenu from './Submenu';
import Footer from '../components/Footer';
import { useRef , useState, useEffect } from "react";
import './Recruit.css';
import { Routes, Route, Link } from 'react-router-dom';
import Recruit1 from './Recruit1';
import Recruit2 from './Recruit2';
import recruit from './Recruit.module.css'

function Recruit (props){


  const subMenu = [
    {
      id:1,
      link:'/recruit/recruit1',
      title:'인재상',
    },
    {
      id:2,
      link:'/recruit/recruit2',
      title:'채용공고',
    },
    
  ]

  const [activeList, setActiveList] = useState();

  return(
    <div className={style.aboutback}>
      <Nav />
      <div className='aboutWrap'>
        <div id={recruit.aboutBg}></div>
          <div className="titBox">
            <h2>인재채용</h2>
            <span>다옴솔루션은 끊임없는 변화와 혁신으로 <br /> IPT/IPCC 분야에서 최고로 인정받는 선도기업입니다.</span>
        </div>
      </div>

      <div id="lnb" className="inner lnb1">
        <div className='inner1200'>   
          <Submenu subMenu={subMenu} activeList={activeList} setActiveList={setActiveList}/>
        </div>
     </div>

     <Routes>
        <Route path="/recruit1" element={<Recruit1 />} />
        <Route path="/recruit2" element={<Recruit2 />} />
     </Routes>

  <Footer />
  </div>
  
  )
}


export default Recruit;