import React from 'react';
import Nav from '../components/Nav';
import style from './About.module.css'
import Submenu from './Submenu';
import Footer from '../components/Footer';
import { Routes, Route, Link } from 'react-router-dom';
import Support1 from './Support1';
import Support2 from './Support2';
import newsStyle from './News.module.css';
import './News.css';
import reportStyle from './ReportTemplate.module.css';
import { Outlet } from 'react-router-dom';

function NoticeTemplate (props){
  console.log(props.news[props.target].img)
  return(

           <section>
              <div className={reportStyle.view_info}>
                <div className={reportStyle.sub}>
                    <h4 id={reportStyle.news_subject}>{props.news[props.target].title}</h4>
                </div>
                <div id="news-date" className={reportStyle.date}>{props.news[props.target].date}</div>
              </div>
          
            <div className={reportStyle.contentWrap}>
              <div className={reportStyle.imgWrap}>
                <img src={props.news[props.target].img} alt="뉴스이미지"
                style={{display : props.news[props.target].img === `/assets/sampleImg.png` ? 'none' : 'block'}}/>
              </div>


              <div className={reportStyle.content}>
                <p className={reportStyle.desc}>{props.news[props.target].desc}
                </p>
              
                {/* <p className={reportStyle.source}>
                  <a href={props.news[props.target]?.source}>※ 출처 : {props.news[props.target].source}
                  </a>
                </p> */}
              </div>
              <div className={reportStyle.button} style={{marginTop:'30px'}}>
                <a href="/news/Notice">
                  <button className={reportStyle.news_list}>목록으로</button>
                </a>
              </div>
            </div>

          </section>

  
  )
}


export default NoticeTemplate;